import React from "react";
import useAuth from "../hooks/useAuth";
import useUser from "../hooks/useUser";
import { Navigate, useLocation } from "react-router-dom";
import { LoadingScreen } from "../pages";
import dayjs from "dayjs";

const ProtectedRouteSubscribed = ({ children }) => {
  const { isAuthenticated } = useAuth();
  const { isSubscribed, loadingUser, subscriptionDate } = useUser();
  const location = useLocation();

  const isFirstDayOfSubscription = () => {
    if (!subscriptionDate) return false;
    const today = dayjs();
    const subscriptionStart = dayjs(subscriptionDate);
    return today.isSame(subscriptionStart, "day");
  };

  const hasDismissedTelegramInfo = () => {
    return localStorage.getItem("dismissedTelegramInfo") === "true";
  };

  if (loadingUser) return <LoadingScreen />;

  if (!isAuthenticated()) {
    return <Navigate to="/assinatura" />;
  }

  if (
    isFirstDayOfSubscription() &&
    !hasDismissedTelegramInfo() &&
    location.pathname !== "/telegram-info"
  ) {
    return <Navigate to="/telegram-info" />;
  }

  return isSubscribed() ? children : <Navigate to="/assinatura" />;
};

export default ProtectedRouteSubscribed;
