import React, { useState, useEffect } from 'react'
import './resetPassword.css'

import { LoginSignUpLayout } from '../../layouts';
import { RequestPasswordReset, SetNewPassword } from '../../sections'
import { FloatingAlert } from '../../components';

import { useLocation, useNavigate } from 'react-router-dom';
import orionAPI from '../../config/orionAPI';

function ResetPassword() {
    const [isTokenAvailable, setIsTokenAvailable] = useState(false);
    const [isPasswordChanged, setIsPasswordChanged] = useState(false);
    const [showPasswordChangedAlert, setShowPasswordChangedAlert] = useState(false);
    const [passwordResetData, setPasswordResetData] = useState({
        password: '',
        token: ''
    });

    const search = useLocation().search;
    const userToken = new URLSearchParams(search).get('t');

    const navigate = useNavigate();

    const isTokenValid = async () => {
        await orionAPI
            .post('/api/reset_password/', {
                password: '123',
                token: userToken
            })
            .then((response) => {
                return true;
            })
            .catch((error) => {
                if (error.response.status === 400) setIsTokenAvailable(false)
            })
    }

    useEffect(() => {
        if (userToken) {
            setIsTokenAvailable(true)
            setPasswordResetData({
                ...passwordResetData,
                token: userToken
            })
            isTokenValid();
        } else {
            setIsTokenAvailable(false)
        }
    }, [])

    const conditionalComponent = () => {
        return isTokenAvailable
            ? (
                <SetNewPassword
                    setPasswordResetData={setPasswordResetData}
                    passwordResetData={passwordResetData}
                    setIsPasswordChanged={setIsPasswordChanged}
                />
            )
            : (
                <RequestPasswordReset />
            )
    };

    useEffect(() => {
        isPasswordChanged ? setShowPasswordChangedAlert(true) : setShowPasswordChangedAlert(false)

        if (showPasswordChangedAlert) {
            setTimeout(function () {
                setShowPasswordChangedAlert(false)
                navigate('/login')
            }, 2000);
        }
    }, [isPasswordChanged, showPasswordChangedAlert])

    return (
        <LoginSignUpLayout>
            {conditionalComponent()}
            {isPasswordChanged
                ? <FloatingAlert
                    text='Senha alterada com sucesso!'
                />
                : <></>
            }
        </LoginSignUpLayout>
    )
}

export default ResetPassword