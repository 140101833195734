import React, { useState } from "react";
import "./footer.css";

import logo from "../../assets/logo-light.png";
import { PrivacyModal, UseTermsModal } from "..";

import linkedin from "../../assets/social-icons/linkedin.svg";
import instagram from "../../assets/social-icons/instagram.svg";
import twitter from "../../assets/social-icons/twitter.svg";
import tiktok from "../../assets/social-icons/tiktok.svg";

function Footer() {
  const [showPrivacyModal, setShowPrivacyModal] = useState(false);
  const [showUseTermsModal, setShowUseTermsModal] = useState(false);
  return (
    <div className="orion__footer">
      <div className="orion__footer-section">
        <img className="orion__footer-logo" src={logo} />
        <div className="orion__footer-message-wrapper">
          <div className="orion__footer-message-policy">
            <small
              className="orion__footer-link"
              onClick={() => setShowUseTermsModal(true)}
            >
              Termos de Uso
            </small>
            <small>|</small>
            <small
              className="orion__footer-link"
              onClick={() => setShowPrivacyModal(true)}
            >
              Política de Privacidade
            </small>
          </div>
          <small className="orion__footer-message">
            Feito com ♥ em São Paulo por Cryptolovers
          </small>
        </div>
      </div>
      <div className="orion__footer-section">
        <div className="orion__social-container">
          <img src={instagram} />
          <img src={twitter} />
          <img src={tiktok} />
          <img src={linkedin} />
        </div>
        <div className="orion__contact-info">
          <small>contato@orionsinais.com.br</small>
          <small>
            Av. Paulista, nº 1636 – Conjunto 4 – Pav. 1504 - São Paulo
          </small>
        </div>
      </div>
      {showPrivacyModal && <PrivacyModal setOpenModal={setShowPrivacyModal} />}
      {showUseTermsModal && (
        <UseTermsModal setOpenModal={setShowUseTermsModal} />
      )}
    </div>
  );
}

export default Footer;
