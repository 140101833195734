import React, { useState } from "react";
import "./requestPasswordReset.css";

import { ClipLoader } from "react-spinners";
import { Button, Input } from "../../../components";
import { CheckCircleIcon } from "@heroicons/react/24/outline";
import orionAPI from "../../../config/orionAPI";

function RequestPasswordReset() {
  const [email, setEmail] = useState("");
  const [isEmailSent, setIsEmailSent] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleResetPasswordRequest = async (event) => {
    event.preventDefault();
    setIsEmailSent(false);
    setLoading(true);

    await orionAPI
      .post("/api/request_password_reset", {
        email: email,
      })
      .then(setIsEmailSent(true), setLoading(false));
  };

  return (
    <div className="orion__reset-password-container">
      <h1>Redefinir senha</h1>
      <form
        className="orion__request-password-reset-form"
        onSubmit={handleResetPasswordRequest}
      >
        <Input
          label="Digite seu e-mail"
          name="email"
          placeholder="bob@email.com"
          onChange={(e) => setEmail(e.target.value)}
          value={email}
          required
        />
        {loading ? (
          <Button styleType="filled">
            <ClipLoader
              color="#ffffff"
              size={20}
              aria-label="Loading Spinner"
              data-testid="loader"
              className="orion__loading"
            />
          </Button>
        ) : (
          <Button
            text="Enviar e-mail de redefinição"
            styleType="filled"
            onClick={handleResetPasswordRequest}
          />
        )}
      </form>
      {isEmailSent ? (
        <div className="orion__email-sent">
          <CheckCircleIcon className="orion__email-sent-icon" />
          <small>E-mail enviado com sucesso!</small>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}

export default RequestPasswordReset;
