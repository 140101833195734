import React from "react";
import "./index.css";
import { useParams, useNavigate } from "react-router-dom";
import { LoginSignUpLayout } from "../../layouts";

import { Button } from "../../components";

import checkoutSuccessImage from "../../assets/checkout-success.svg";
import checkoutCancelledImage from "../../assets/checkout-cancelled.svg";

function Checkout() {
  const { state } = useParams();

  const navigate = useNavigate();

  const handleSubmit = () => {
    state === "success" ? navigate("/telegram-info") : navigate("/assinatura");
  };

  return (
    <LoginSignUpLayout>
      <div className="orion__checkout">
        <img
          src={
            state === "success" ? checkoutSuccessImage : checkoutCancelledImage
          }
        />
        {state === "success" ? (
          <div className="orion__checkout-text">
            <h1>Pagamento confirmado!</h1>
            <p>
              Parabéns! Seu pagamento foi confirmado e você já tem acesso à
              Orion Sinais. Clique no botão abaixo para navegar para dashboard.
            </p>
          </div>
        ) : (
          <div className="orion__checkout-text">
            <h1>Seu pagamento foi cancelado</h1>
            <p>
              Poxa! Não podemos concluir seu pagamento. Clique no botão abaixo
              para voltar a página de assinatura.
            </p>
          </div>
        )}
        <Button
          styleType="filled"
          text={
            state === "success"
              ? "Ir para a Dashboard"
              : "Retornar ao pagamento"
          }
          onClick={handleSubmit}
        />
      </div>
    </LoginSignUpLayout>
  );
}

export default Checkout;
