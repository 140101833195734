import { useState, useEffect } from 'react'

import axios from 'axios'

const useStatesAndCities = () => {
    const [states, setStates] = useState()
    const [cities, setCities] = useState()

    const fetchStates = async () => {
        await axios.get(`https://servicodados.ibge.gov.br/api/v1/localidades/estados?orderBy=nome`)
            .then((response) => {
                setStates(response.data)
            })
            .catch((error) => {
                console.log(error)
            })
    }

    const fetchCities = async () => {
        await axios.get(`https://servicodados.ibge.gov.br/api/v1/localidades/municipios?orderBy=nome`)
            .then((response) => {
                setCities(response.data)
            })
            .catch((error) => {
                console.log(error)
            })
    }

    const fetchCitiesByStateId = async (stateId) => {
        await axios.get(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${stateId}/municipios`)
            .then((response) => {
                setCities(response.data);
            })
            .catch((error) => {
                console.log(error)
            })
    }


    useEffect(() => {
        fetchStates()
        fetchCities()
    }, [])

    return { states, cities, fetchCitiesByStateId }
}

export default useStatesAndCities;