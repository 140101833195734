import React, { useEffect } from "react";
import "./index.css";

import axios from "axios";
import { Input, Button } from "../../../../components";
import { CityAndStateDropdown } from "../../components";
import useStatesAndCities from "../../../../hooks/useStatesAndCities";
import { formatCEP } from "../../helpers/formatHelpers";

function Address({ formData, setFormData, errors, setErrors, handleSubmit }) {
  const { states, cities, fetchCitiesByStateId } = useStatesAndCities();

  const setCEPValues = async (e) => {
    let reformatCEP = formatCEP(e, setFormData);
    if (reformatCEP.length == 9) {
      let formattedCEP = reformatCEP.replace(/\D/g, "");

      await axios
        .get(`https://viacep.com.br/ws/${formattedCEP}/json/`)
        .then((response) => {
          if (response.data.erro) {
            console.error(`Erro ao obter dados do cep ${formattedCEP}.`);
            setErrors({
              ...errors,
              cep: "CEP inválido",
            });
          } else if (response.data) {
            setFormData({
              ...formData,
              address: {
                ...formData.address,
                cep: response.data.cep,
                street: response.data.logradouro,
                state: response.data.uf,
                city: response.data.localidade,
              },
            });
            setErrors({
              ...errors,
              cep: null,
            });
          } else {
            console.error(
              `Dados do cep ${formattedCEP} não estão disponíveis`,
              response.error
            );
            setErrors({
              ...errors,
              cep: "CEP inválido",
            });
          }
        });
    }
  };

  useEffect(() => {
    if (states && formData.address.country === "BR") {
      let state = states.find((item) => {
        return item.sigla === formData.address.state;
      });
      fetchCitiesByStateId(state.id);
    }
  }, [formData?.address.state]);

  return (
    <div className="orion__su-column">
      <h2>Endereço</h2>
      <div className="orion__su-form">
        {formData.address.country == "BR" ? (
          <div className="input-wrap">
            <Input
              label="CEP"
              placeholder="00000-000"
              onChange={setCEPValues}
              value={formData.address.cep}
              maxLength="9"
              error={errors?.addressErrors?.cep || errors?.cep}
              required
            />
          </div>
        ) : (
          <Input
            label="Código Postal"
            placeholder="Código Postal"
            onChange={(e) =>
              setFormData({
                ...formData,
                address: {
                  ...formData.address,
                  cep: e.target.value,
                },
              })
            }
            value={formData.address.cep}
            maxLength="15"
            error={errors?.addressErrors?.cep || errors?.cep}
            required
          />
        )}
        <Input
          label="Rua"
          placeholder="Rua dos Bobos"
          onChange={(e) =>
            setFormData({
              ...formData,
              address: {
                ...formData.address,
                street: e.target.value,
              },
            })
          }
          value={formData.address.street}
          error={errors?.addressErrors?.street || errors?.street}
          required
        />
        <div className="orion__double-input">
          <Input
            label="Número"
            placeholder="Número da casa"
            onChange={(e) =>
              setFormData({
                ...formData,
                address: {
                  ...formData.address,
                  number: e.target.value,
                },
              })
            }
            value={formData.address.number}
            error={errors?.addressErrors?.number || errors?.number}
            required
          />
          <Input
            label="Complemento"
            placeholder="Complemento"
            onChange={(e) =>
              setFormData({
                ...formData,
                address: {
                  ...formData.address,
                  additional: e.target.value,
                },
              })
            }
            value={formData.address.additional}
            error={errors?.addressErrors?.additional || errors?.additional}
          />
        </div>
        {formData.address.country == "BR" ? (
          <div className="orion__double-input">
            {states && (
              <CityAndStateDropdown
                label="estado"
                options={states}
                setData={setFormData}
                data={formData}
                currentSelected={formData.address.state}
                error={errors?.addressErrors?.state || errors?.state}
              />
            )}
            {cities && (
              <CityAndStateDropdown
                label="cidade"
                options={cities}
                setData={setFormData}
                data={formData}
                currentSelected={formData.address.city}
                error={errors?.addressErrors?.city || errors?.city}
              />
            )}
          </div>
        ) : (
          <div className="orion__double-input">
            <Input
              label="Estado"
              placeholder="Estado"
              onChange={(e) =>
                setFormData({
                  ...formData,
                  address: {
                    ...formData.address,
                    state: e.target.value,
                  },
                })
              }
              value={formData.address.state}
              maxLength={2}
              error={errors?.addressErrors?.state || errors?.state}
              required
            />
            <Input
              label="Cidade"
              placeholder="Cidade"
              onChange={(e) =>
                setFormData({
                  ...formData,
                  address: {
                    ...formData.address,
                    city: e.target.value,
                  },
                })
              }
              value={formData.address.city}
              error={errors?.addressErrors?.city || errors?.city}
              required
            />
          </div>
        )}
      </div>
      <div className="orion__su-button">
        <Button styleType="filled" onClick={handleSubmit} text={"Finalizar"} />
      </div>
    </div>
  );
}

export default Address;
