export const trades = [
  {
    simbolo: "btc",
    nome: "Bitcoin",
    dataCompra: "29/01/2024",
    dataVenda: "21/03/2024",
    duracao: "52 dias",
    percentual: "+51.26%",
    valorCompra: 43302.7,
    valorVenda: 65501.27,
  },
  {
    simbolo: "eth",
    nome: "Ethereum",
    dataCompra: "18/09/2023",
    dataVenda: "29/11/2023",
    duracao: "72 dias",
    percentual: "+23.96%",
    valorCompra: 1636.66,
    valorVenda: 2028.81,
  },
  {
    simbolo: "floki",
    nome: "Floki",
    dataCompra: "12/02/2024",
    dataVenda: "21/03/2024",
    duracao: "38 dias",
    percentual: "+568.6%",
    valorCompra: 3.201e-5,
    valorVenda: 2.1402e-4,
  },
  {
    simbolo: "pepe",
    nome: "Pepe",
    dataCompra: "13/01/2024",
    dataVenda: "18/03/2024",
    duracao: "65 dias",
    percentual: "+445.6%",
    valorCompra: 1.25e-6,
    valorVenda: 6.82e-6,
  },
  {
    simbolo: "arkm",
    nome: "Arkham",
    dataCompra: "12/02/2024",
    dataVenda: "21/03/2024",
    duracao: "38 dias",
    percentual: "+320.07%",
    valorCompra: 0.6174,
    valorVenda: 2.5935,
  },

  {
    simbolo: "ada",
    nome: "Cardano",
    dataCompra: "01/11/2023",
    dataVenda: "05/01/2024",
    duracao: "65 dias",
    percentual: "+76.29%",
    valorCompra: 0.3071,
    valorVenda: 0.5414,
  },
  {
    simbolo: "sol",
    nome: "Solana",
    dataCompra: "01/12/2023",
    dataVenda: "14/01/2024",
    duracao: "44 dias",
    percentual: "+57.0%",
    valorCompra: 59.75,
    valorVenda: 93.81,
  },
];
