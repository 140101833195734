import React, { useEffect, useState } from "react";
import "./index.css";

import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/24/outline";

function CityAndStateDropdown({
  options,
  label,
  setData,
  data,
  currentSelected,
  style,
  error,
}) {
  const [isOptionsOpen, setIsOptionsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(-1);

  const toggleOptions = () => {
    setIsOptionsOpen(!isOptionsOpen);
  };

  const setSelectedThenCloseDropdown = (option) => {
    setIsOptionsOpen(false);
    if (label === "cidade") {
      setData({
        ...data,
        address: {
          ...data.address,
          city: option.nome,
        },
      });
    }
    if (label === "estado") {
      setData({
        ...data,
        address: {
          ...data.address,
          state: option.sigla,
        },
      });
    }
    if (label === "país") {
      setData(option.cca2);
    }
  };

  useEffect(() => {
    if (currentSelected) {
      if (label === "estado") {
        setSelectedOption(
          options.findIndex((item, index) => {
            return item.sigla == currentSelected;
          })
        );
      } else if (label === "cidade") {
        setSelectedOption(
          options.findIndex((item, index) => {
            return item.nome == currentSelected;
          })
        );
      } else if (label === "país") {
        setSelectedOption(
          options.findIndex((item, index) => {
            return item.cca2 == currentSelected;
          })
        );
      }
    }
  }, [currentSelected, options]);

  return (
    <div className="orion__dropdown-wrapper">
      <div className="orion__dropdown-container">
        <div
          className={
            error
              ? "orion__dropdown-label-wrapper error"
              : "orion__dropdown-label-wrapper"
          }
        >
          {style === "dark" ? <></> : <small>{label.toUpperCase()}</small>}
          <button
            className={
              error
                ? `orion__dropdown ${style} error`
                : `orion__dropdown ${style}`
            }
            type="button"
            onClick={toggleOptions}
          >
            {options[selectedOption]
              ? label === "estado"
                ? options[selectedOption].sigla
                : label === "cidade"
                ? options[selectedOption].nome
                : label === "país"
                ? options[selectedOption].name.common
                : ""
              : "Selecione..."}
            {isOptionsOpen ? (
              <ChevronUpIcon className="orion__dropdown-icon" />
            ) : (
              <ChevronDownIcon className="orion__dropdown-icon" />
            )}
          </button>
          {error ? <small>{error}</small> : <></>}
        </div>
      </div>
      <ul
        className={`options-dp ${isOptionsOpen ? "show" : ""} ${style}`}
        role="listbox"
      >
        {options ? (
          options.map((option, index) => (
            <li
              key={index}
              id={option}
              role="option"
              aria-selected={selectedOption == index}
              tabIndex={0}
              onClick={() => {
                setSelectedThenCloseDropdown(option, index);
              }}
            >
              {label === "estado" ? (
                option.sigla
              ) : label === "cidade" ? (
                option.nome
              ) : label === "país" ? (
                option.name.common
              ) : (
                <></>
              )}
            </li>
          ))
        ) : (
          <></>
        )}
      </ul>
    </div>
  );
}

export default CityAndStateDropdown;
