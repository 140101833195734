import { useState, useEffect } from 'react'

import orionAPI from '../../../config/orionAPI'

const useCoinPairList = () => {
    const [coinPairList, setCoinPairList] = useState([])
    const [loadingCoinPairList, setLoadingCoinPairList] = useState(true)

    const fetchCoinPairList = async () => {
        setLoadingCoinPairList(true)

        await orionAPI.get('/api/dashboard/crypto_pairs')
            .then((response) => {
                const sortedPairList = [
                    ...response.data.filter(({ pair }) => pair === "BTCUSDT" || pair === "ETHUSDT"),
                    ...response.data.filter(({ pair }) => pair !== "BTCUSDT" && pair !== "ETHUSDT")
                ];
                setCoinPairList(sortedPairList)
                setLoadingCoinPairList(false)
            })
            .catch((error) => {
                console.log(error);
            });
    }


    useEffect(() => {
        fetchCoinPairList()
    }, [])

    return { coinPairList, loadingCoinPairList }
}

export default useCoinPairList;