import React from 'react'
import './floatingAlert.css'

import { CheckCircleIcon } from '@heroicons/react/24/outline'

function FloatingAlert({ text }) {
    return (
        <div className='orion__floating-alert'>
            <CheckCircleIcon className='orion__floating-alert-icon' />
            <small>{text}</small>
        </div>
    )
}

export default FloatingAlert