
import axios from 'axios'

const orionAPI = axios.create({
    baseURL: process.env.REACT_APP_ORION_API_HOST,
    headers: {
        'Content-Type': 'application/json'
    }
})

orionAPI.interceptors.request.use(
    function (config) {
        const token = localStorage.getItem("userToken");
        if (token) {
            config.headers["Authorization"] = 'Bearer ' + token;
        }
        return config;
    },
    function (error) {
        return Promise.reject(error);
    }
);

orionAPI.interceptors.response.use(response => {
    return response;
}, error => {
    if (error.response) {
        if (error.response.status === 401) {
            localStorage.removeItem('userToken')
        }
    }
    return Promise.reject(error);
});

export default orionAPI;