import React, { useState, useEffect } from "react";
import "./index.css";

import {
  LightBulbIcon,
  HeartIcon,
  SparklesIcon,
} from "@heroicons/react/24/outline";
import { useNavigate } from "react-router-dom";

import logo from "../../assets/logo-light.png";
import kleber from "../../assets/about-us/kleber.png";
import lais from "../../assets/about-us/lais.png";
import rafael from "../../assets/about-us/rafael.png";
import matheus from "../../assets/about-us/matheus.png";
import marilia from "../../assets/about-us/marilia.png";

import logoLightSymbol from "../../assets/logo-light-symbol.png";
import logoSymbol from "../../assets/logo-light-symbol.png";
import { Footer } from "../../components";
import { Navbar } from "../landingPage/components";

function AboutUs() {
  return (
    <div className="orion__about-us-page">
      <Navbar page={"about-us"} />
      <div className="orion__about-us">
        <img src={logoLightSymbol} />
        <h2>Olá! Somos Kleber, Laís e Rafael, fundadores da Orion Sinais!</h2>
        <p>
          {" "}
          Já teve o sentimento de que tudo relacionado a criptoativos é mais
          difícil do que deveria? A partir dessa dor comum (e nossa também!)
          decidimos criar a Orion Sinais, com a missão de ensinar e facilitar o
          uso de criptoativos! A Orion Sinais nasceu no ano de 2024 em São
          Paulo, com a missão de impulsionar o futuro através da inovação.{" "}
        </p>
      </div>
      <div className="orion__our-history">
        <div className="orion__our-history-header">
          <h1>NOSSA HISTÓRIA</h1>
          <div className="orion__our-history-header-content">
            <p>
              Imersos no empreendedorismo, na Babson College em Boston - MA,
              embarcamos em uma jornada para criar a Orion Sinais unidos por uma
              paixão compartilhada por tecnologia, blockchain e criptomoedas.
            </p>
            <p>
              Com mais de 20 anos de experiência combinada em diversos setores,
              cada um de nós traz algo único para a equipe:{" "}
            </p>
          </div>
        </div>
      </div>
      <div className="orion__our-history-column">
        <div className="orion__profile">
          <img src={kleber} />
          <div className="orion__profile-text">
            <h2>KLEBER</h2>
            <p>
              Um mestre em estratégia de negócios com um histórico de sucesso na
              construção e crescimento de empresas inovadoras, com um talento
              especial para identificar oportunidades de mercado e traduzi-las
              em oportunidades concretas e eficazes.
            </p>
          </div>
        </div>
        <div className="orion__profile">
          <img src={lais} />
          <div className="orion__profile-text">
            <h2>LAÍS</h2>
            <p>
              Uma visionária criativa que traduz suas ideias inovadoras em
              soluções tecnológicas robustas e escaláveis, colocando em prática
              toda sua expertise de sua carreira técnica de desenvolvimento de
              sistemas.
            </p>
          </div>
        </div>
        <div className="orion__profile">
          <img src={rafael} />
          <div className="orion__profile-text">
            <h2>RAFAEL</h2>
            <p>
              Um especialista em segurança da informação, sempre atento às
              últimas ameaças e vulnerabilidades. É um solucionador de conflitos
              nato, capaz de negociar e mediar soluções eficazes para problemas
              complexos. Sua habilidade de gestão de negócios o torna um líder
              estratégico, capaz de tomar decisões acertadas que impulsionam o
              sucesso da equipe.
            </p>
          </div>
        </div>
        <div className="orion__profile">
          <img src={matheus} />
          <div className="orion__profile-text">
            <h2>MATHEUS</h2>
            <p>
              Desenvolvedor full-stack com mais de cinco anos de experiência em
              desenvolvimento web, com base sólida em uma variedade de
              tecnologias e frameworks. Suas experiências inclui Elixir, NodeJS,
              React, Java, PHP, AWS, Docker e Kafka. Com histórico em entrega de
              projetos de sucesso e altamente qualificado em projetar e
              construir arquiteturas de microsserviços. Em constante
              atualização, dedica-se em melhorar continuamente suas habilidades
              de programação.
            </p>
          </div>
        </div>
        <div className="orion__profile">
          <img src={marilia} />
          <div className="orion__profile-text">
            <h2>MARÍLIA</h2>
            <p>
              Desenvolvedora com formação em Ciência da Computação que atua como
              Desenvolvedora Front-end e Designer UI/UX na Orion. Possui
              conhecimentos e vivência em Design de experiência de usuário,
              Estudo de usuário, Design System, Design Responsivo, Figma,
              Elixir, Phoenix, React.js, e em bancos dedados relacionais como
              MySQL e PostgreSQL.
            </p>
          </div>
        </div>
      </div>
      <div className="orion__our-vision">
        <h1>Nossa Visão</h1>
        <p>
          A Orion está aqui para ajudar as pessoas a aproveitarem as
          oportunidades do mercado de criptoativos, fornecendo soluções
          inovadoras e personalizadas que facilitam a tomada de decisão sobre o
          melhor momento para realizar seus investimentos.{" "}
        </p>
      </div>
      <div className="orion__our-values">
        <h1>Nossos Valores</h1>
        <div className="orion__value">
          <div className="orion__value-header">
            <LightBulbIcon className="orion__value-icon" />

            <h2>INOVAÇÃO</h2>
          </div>
          <p>
            Buscamos constantemente novas e melhores maneiras de fazer
            investimentos, desafiando o status quo e impulsionando a evolução do
            seu patrimônio.
          </p>
        </div>
        <div className="orion__value">
          <div className="orion__value-header">
            <HeartIcon className="orion__value-icon" />

            <h2>PAIXÃO</h2>
          </div>
          <p>
            Somos apaixonados pelo que fazemos e dedicados a criar soluções que
            realmente façam a diferença.
          </p>
        </div>
        <div className="orion__value">
          <div className="orion__value-header">
            <SparklesIcon className="orion__value-icon" />

            <h2>TRANSPARÊNCIA</h2>
          </div>
          <p>
            Acreditamos que a transparência é fundamental para construir uma
            relação de confiança com nossos clientes. Agimos com honestidade e
            responsabilidade em todas as nossas interações, utilizando
            ferramentas e análises imparciais que evidenciam ganhos e perdas.
          </p>
        </div>
      </div>
      <div className="orion__join-us">
        <h1>Junte-se a nós!</h1>
        <h2>Venha fazer parte da revolução cripto</h2>
      </div>
      <Footer />
    </div>
  );
}

export default AboutUs;
