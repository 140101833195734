import React, { useEffect, useState } from "react";
import "./subscription.css";
import { useNavigate } from "react-router-dom";

import { Navbar, FloatingAlert, Footer } from "../../components";
import {
  SubscriptionFirstSection,
  SubscriptionSecondSection,
} from "../../sections";
import { ExclamationCircleIcon } from "@heroicons/react/24/outline";
import orionAPI from "../../config/orionAPI";

import useUser from "../../hooks/useUser";

function Subscription() {
  const [page, setPage] = useState(1);
  const [subscriptionPlans, setSubscriptionPlans] = useState([]);
  const [showPlanNotSelectedWarning, setShowPlanNotSelectedWarning] =
    useState(false);
  const [selectedPlan, setSelectedPlan] = useState({});
  const [loading, setLoading] = useState(true);
  const [paymentConfirmed, setPaymentConfirmed] = useState(false);
  const [showPaymentAlert, setShowPaymentAlert] = useState(false);

  const { user } = useUser();
  const navigate = useNavigate();

  const getSubscriptionPlans = async () => {
    setLoading(true);
    await orionAPI
      .get("api/users/me/subscription_plans")
      .then(function (response) {
        setSubscriptionPlans(response.data.data);
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const conditionalComponent = () => {
    switch (page) {
      case 1:
        return (
          <SubscriptionFirstSection
            subscriptionPlans={subscriptionPlans}
            selectedPlan={selectedPlan}
            setSelectedPlan={setSelectedPlan}
            loading={loading}
            setShowPlanNotSelectedWarning={setShowPlanNotSelectedWarning}
            page={page}
            setPage={setPage}
          />
        );
      case 2:
        return (
          <SubscriptionSecondSection
            subscriptionPlans={subscriptionPlans}
            selectedPlan={selectedPlan}
            page={page}
            setPage={setPage}
            setSelectedPlan={setSelectedPlan}
            loading={loading}
            setLoading={setLoading}
            setPaymentConfirmed={setPaymentConfirmed}
          />
        );
    }
  };

  useEffect(() => {
    getSubscriptionPlans();
  }, []);

  useEffect(() => {
    paymentConfirmed ? setShowPaymentAlert(true) : setShowPaymentAlert(false);

    if (showPaymentAlert) {
      setTimeout(function () {
        setShowPaymentAlert(false);
        navigate("/dashboard");
      }, 2000);
    }
  }, [paymentConfirmed, showPaymentAlert]);

  return (
    <div className="orion__page">
      <Navbar />
      <div className="orion__subscription">
        <div className="orion__subscription-container">
          <div className="orion__container-header">
            <h1 className="orion__title">Realize o pagamento do seu plano</h1>
            <small>ACEITAMOS SOMENTE PAGAMENTOS VIA PIX</small>
          </div>
          <div className="orion__subscription-section">
            {conditionalComponent()}
          </div>
          {showPlanNotSelectedWarning ? (
            <div className="orion__plan-not-selected-warning">
              <ExclamationCircleIcon className="orion__warning-icon" />
              <small>Selecione um plano para continuar</small>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
      {showPaymentAlert ? (
        <FloatingAlert text="Pagamento confirmado!" />
      ) : (
        <></>
      )}
      <Footer />
    </div>
  );
}

export default Subscription;
