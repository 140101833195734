import React from 'react'
import './index.css'

import { formatPair, getPairCoinSymbol } from '../../helpers/pairHelpers'
import useIcons from '../../../../hooks/useIcons'

function ChartInfo({ pairName, initialDate, endDate, formatDate }) {
  const { getIconByCoinSymbol } = useIcons();
  return (
    <div className='orion__chart-info'>
      <div className='orion__pair-info'>
        <div className='coin-img-container'>
          {getIconByCoinSymbol(getPairCoinSymbol(pairName))
            ? <img className='coin-img' src={getIconByCoinSymbol(getPairCoinSymbol(pairName))} />
            : <small>{pairName.substring(0, 1)}</small>
          }
        </div>
        {formatPair(pairName)}
      </div>
      <div className='orion__time-period'>
        <div className='orion__info-column'>
          <small className='orion__column-title'>DATA INICIAL</small>
          <small className='orion__column-text'>{formatDate(initialDate).replaceAll(',', '')}</small>
        </div>
        <div className='orion__info-column'>
          <small className='orion__column-title'>DATA FINAL</small>
          <small className='orion__column-text'>{formatDate(endDate).replaceAll(',', '')}</small>
        </div>
      </div>
    </div>
  )
}

export default ChartInfo