import React from "react";
import "./index.css";
import { PersonalInfo, Address } from "../";
import { ClipLoader } from "react-spinners";

function SignUpForm({
  formData,
  setFormData,
  errors,
  setErrors,
  setPage,
  pageErrors,
  handleSubmit,
  loading,
}) {
  return (
    <div className="orion__su-double-form">
      <PersonalInfo
        formData={formData}
        setFormData={setFormData}
        errors={errors}
        setErrors={setErrors}
        setPage={setPage}
        pageErrors={pageErrors}
        handleSubmit={handleSubmit}
      />
      <Address
        formData={formData}
        setFormData={setFormData}
        errors={errors}
        setErrors={setErrors}
        setPage={setPage}
        pageErrors={pageErrors}
        handleSubmit={handleSubmit}
      />
      {loading ? (
        <div className="orion__loading-overlay">
          <ClipLoader
            color="#D42A5B"
            size={50}
            aria-label="Loading Spinner"
            data-testid="loader"
            className="orion__loading"
          />
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}

export default SignUpForm;
