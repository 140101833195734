import { useState, useEffect } from "react";

import orionAPI from "../config/orionAPI";

const useUser = () => {
  const [user, setUser] = useState({
    id: "",
    address: {},
    cpf: "",
    email: "",
    last_name: "",
    first_name: "",
    birthday: "",
    phone_number: "",
    subscription: "",
  });
  const [loadingUser, setLoadingUser] = useState(true);

  const fetchUser = async () => {
    setLoadingUser(true);

    await orionAPI
      .get(`/api/users/me`)
      .then((response) => {
        setUser(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoadingUser(false);
      });
  };

  const isSubscribed = () => {
    return user.subscription;
  };

  useEffect(() => {
    fetchUser();
  }, []);

  return {
    fetchUser,
    user,
    isSubscribed,
    subscriptionDate: user.subscription?.activation_date,
    loadingUser,
  };
};

export default useUser;
