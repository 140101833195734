import React, { useState } from "react";
import "./index.css";

import { formatPair, getPairCoinSymbol } from "../../helpers/pairHelpers";
import useIcons from "../../../../hooks/useIcons";

import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/24/outline";
import SearchBar from "../../../../components/searchBar/SearchBar";

function PairSelector({ label, pairList, setCoinPairSymbol }) {
  const [isOptionsOpen, setIsOptionsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(0);
  const [search, setSearch] = useState("");

  const { getIconByCoinSymbol } = useIcons();

  const toggleOptions = () => {
    setIsOptionsOpen(!isOptionsOpen);
  };

  const setSelectedThenCloseDropdown = (option, index) => {
    setSelectedOption(index);
    setIsOptionsOpen(false);
    setCoinPairSymbol(option.pair);
  };

  return (
    <div className="wrapper">
      <div className="container">
        <div className="orion__label-wrapper">
          <small>{label.toUpperCase()}</small>
          <button
            className="pair-selector"
            type="button"
            onClick={toggleOptions}
          >
            <div className="coin-info">
              <div className="coin-img-container">
                {getIconByCoinSymbol(
                  getPairCoinSymbol(pairList[selectedOption].pair)
                ) ? (
                  <img
                    className="coin-img"
                    src={getIconByCoinSymbol(
                      getPairCoinSymbol(pairList[selectedOption].pair)
                    )}
                  />
                ) : (
                  <small>{pairList[selectedOption].name.substring(0, 1)}</small>
                )}
              </div>
              <div className="button-text">
                <h4>{formatPair(pairList[selectedOption].pair)}</h4>
                <small>{pairList[selectedOption].name}/Tether USD</small>
              </div>
            </div>
            {isOptionsOpen ? (
              <ChevronUpIcon className="button-icon" />
            ) : (
              <ChevronDownIcon className="button-icon" />
            )}
          </button>
        </div>
        <ul
          className={`options-ps ${isOptionsOpen ? "show" : ""}`}
          role="listbox"
        >
          <div className="orion__pair-search-container">
            <SearchBar
              placeholder="Pesquise por um par..."
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
          {pairList
            .filter((item) => {
              return search === ""
                ? item
                : item.pair.toLowerCase().includes(search.toLowerCase()) ||
                    item.name.toLowerCase().includes(search.toLowerCase());
            })
            .map((option, index) => (
              <li
                key={option.pair}
                id={formatPair(option.pair)}
                role="option"
                aria-selected={selectedOption == index}
                tabIndex={0}
                onClick={() => {
                  setSelectedThenCloseDropdown(option, index);
                }}
              >
                <div className="option-name">
                  <div className="coin-img-container">
                    {getIconByCoinSymbol(getPairCoinSymbol(option.pair)) ? (
                      <img
                        className="coin-img"
                        src={getIconByCoinSymbol(
                          getPairCoinSymbol(option.pair)
                        )}
                      />
                    ) : (
                      <small>{option.pair.substring(0, 1)}</small>
                    )}
                  </div>
                  <h4>{option.name}</h4>
                </div>
                <small>{formatPair(option.pair)}</small>
              </li>
            ))}
        </ul>
      </div>
    </div>
  );
}

export default PairSelector;
