import React, { useEffect, useState } from "react";
import "./input.css";

function Input({
  label,
  placeholder,
  type,
  onChange,
  value,
  customStyle,
  maxLength,
  error,
  name,
}) {
  const [inputCustomStyle, setInputCustomStyle] = useState("");

  const getInputStyle = () => {
    switch (customStyle) {
      case "dark":
        return error ? "orion__input-dark-error" : "orion__input-dark";
      default:
        return error ? "orion__input-error" : "orion__input";
    }
  };

  useEffect(() => {
    setInputCustomStyle(getInputStyle());
  }, [error]);

  function isString(value) {
    if (typeof value === "string") {
      return true;
    } else {
      return false;
    }
  }

  return (
    <div className="orion__input-container">
      {error ? (
        isString(error) ? (
          <small
            className={
              customStyle === "dark" ? "input-dark-error" : "input-error"
            }
          >
            {error}
          </small>
        ) : (
          error.map((item) => {
            return (
              <small
                className={
                  customStyle === "dark" ? "input-dark-error" : "input-error"
                }
              >
                {item}
              </small>
            );
          })
        )
      ) : (
        <></>
      )}
      <input
        className={inputCustomStyle}
        name={name}
        type={type}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        maxLength={maxLength}
      />
      <small
        className={
          customStyle === "dark"
            ? "orion__input-label-dark"
            : "orion__input-label"
        }
      >
        {label.toUpperCase()}
      </small>
    </div>
  );
}

export default Input;
