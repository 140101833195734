import React, { useState, useEffect } from 'react';
import './index.css'

import useIcons from '../../../../hooks/useIcons';
import { getPairCoinSymbol, formatPair } from '../../helpers/pairHelpers';

function SignalCard({ pairSymbol, buyDate, buyValue, profitPercentual, sellValue }) {
    const [signalOpenTime, setSignalOpenTime] = useState('')

    const { getIconByCoinSymbol } = useIcons();

    const getSignalOpenTime = () => {
        let buyDateFormatted = new Date(buyDate.split(' ')[0]);
        let currentDate = new Date();
        let differenceInDays = Math.floor((currentDate - buyDateFormatted) / (1000 * 60 * 60 * 24));

        setSignalOpenTime(differenceInDays);
    }

    const formatBuyDate = (date) => {
        const formattedDate = new Date(date.split(' ')).toLocaleString('pt-BR').split(',')[0]

        return formattedDate
    }

    useEffect(() => {
        getSignalOpenTime()
    }, []);

    return (
        <div className='orion__signal-card'>
            <div className='orion__signal-header'>
                <div className='orion__pair-description'>
                    <div className='coin-img-container'>
                        {getIconByCoinSymbol(getPairCoinSymbol(pairSymbol))
                            ? <img className='coin-img' src={getIconByCoinSymbol(getPairCoinSymbol(pairSymbol))} />
                            : <small>{pairSymbol.substring(0, 1)}</small>
                        }
                    </div>
                    <p className='orion__pair-symbol'>{formatPair(pairSymbol)}</p>
                </div>
                <small className='orion__time-opened'>
                    Aberto há {signalOpenTime} {(signalOpenTime > 1) ? 'dias' : 'dia'}
                </small>
            </div>
            <div className='orion__signal-content'>
                <div className='orion__content-container-buy'>
                    <h4 className='orion__separator-name'>COMPRA</h4>
                    <div className='orion__content-separator'>
                        <div className='orion__column'>
                            <h5 className='orion__content-column-name'>Data</h5>
                            <small className='orion__content-column-text'>{formatBuyDate(buyDate)}</small>
                        </div>
                        <div className='orion__column'>
                            <h5 className='orion__content-column-name'>Valor</h5>
                            <small className='orion__content-column-text'>{buyValue.replace('.', ',')}</small>
                        </div>
                    </div>
                </div>
                <div className='orion__content-container-partial'>
                    <h4 className='orion__separator-name'>PARCIAL</h4>
                    <div className='orion__content-separator'>
                        <div className='orion__column'>
                            <h5 className='orion__content-column-name'>Valor</h5>
                            <small className='orion__content-column-text'>{sellValue.replace('.', ',')}</small>
                        </div>
                        <div className='orion__column'>
                            <h5 className='orion__content-column-name'>Lucro</h5>
                            <small className='orion__content-column-text'>{profitPercentual.replace('.', ',')}</small>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SignalCard