import React, { useEffect, useState } from "react";
import "./index.css";

import useUser from "../../../../hooks/useUser";
import useFavorites from "../../../../hooks/useFavorites";

import { ClipLoader } from "react-spinners";

function TelegramNotification() {
  const { user, loadingUser } = useUser();
  const { changeNotificationStatus, telegramLink } = useFavorites();
  const [notificationStatus, setNotificationStatus] = useState(false);

  useEffect(() => {
    if (!loadingUser) {
      setNotificationStatus(
        user.notification_configuration.enable_chatbot_notifications
      );
    }
  }, [user]);

  const handleSubmit = async (e) => {
    if (!notificationStatus) {
      window.open(telegramLink, "_blank", "noopener,noreferrer");
    }

    await changeNotificationStatus(!notificationStatus);
  };

  return (
    <div className="orion__account-section">
      <h2>Notificações do Telegram</h2>
      {loadingUser ? (
        <ClipLoader
          color="#D42A5B"
          size={50}
          aria-label="Loading Spinner"
          data-testid="loader"
          className="orion__loading"
        />
      ) : (
        <>
          <div className={"orion__uc-telegram-status-message"}>
            <p>As notificações do Telegram estão</p>
            <p
              className={
                "orion__telegram-status " + notificationStatus
                  ? "active"
                  : "disabled"
              }
            >
              {notificationStatus ? " ATIVADAS" : " DESATIVADAS"}
            </p>
          </div>
          <p>
            Clique no botão abaixo para{" "}
            {notificationStatus ? "desativar." : "ativar."}
          </p>
          <div className={"orion__uc-telegram-button"} onClick={handleSubmit}>
            {notificationStatus ? "DESATIVAR" : "ATIVAR"}
          </div>
        </>
      )}
    </div>
  );
}

export default TelegramNotification;
