import React from "react";
import "./index.css";

import { Button } from "../../components";
import useFavorites from "../../hooks/useFavorites";
import { ClipLoader } from "react-spinners";
import { useNavigate } from "react-router-dom";

import TelegramPromo from "../../assets/telegram-promo.png";

function TelegramInfo() {
  const { telegramLink, loadingLink } = useFavorites();

  const navigate = useNavigate();

  const handleDismiss = () => {
    localStorage.setItem("dismissedTelegramInfo", "true");
    navigate("/dashboard");
  };

  return (
    <div className="orion__favorite-selection">
      <div className="orion__fs-container">
        <div className="orion__fs-column presentation">
          <div className="orion__fs-presentation-image">
            <img src={TelegramPromo} alt="Telegram Promo"></img>
          </div>
          <div className="orion__fs-presentation-text">
            <h1>Conheça nosso sistema do Telegram</h1>
            <p>
              Você sabia que ao começar uma conversa com nosso bot você recebe
              atualizações dos seus sinais favoritos pelo Telegram?
            </p>
            <p>
              Clique no botão abaixo para entrar em contato com o nosso bot!
            </p>
            <div className="orion__fs-button-wrapper">
              {loadingLink ? (
                <ClipLoader />
              ) : (
                <Button
                  text="Conversar com o nosso bot"
                  name="bot_link"
                  styleType="filled"
                  onClick={() => window.open(telegramLink)}
                />
              )}
              <a onClick={() => handleDismiss()}>
                Não desejo me inscrever agora →
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TelegramInfo;
