import React from "react";
import "./privacyModal.css";

import { XMarkIcon } from "@heroicons/react/24/outline";

function PrivacyModal({ setOpenModal }) {
  return (
    <div className="modalBackground">
      <div className="modalContainer">
        <div className="headerWrapper">
          <div className="title">
            <h1>Política de Privacidade</h1>
          </div>
          <div className="titleCloseBtn">
            <button
              onClick={() => {
                setOpenModal(false);
              }}
            >
              <XMarkIcon className="modal-icon" />
            </button>
          </div>
        </div>

        <div className="body">
          <p className="bold">Informações do Usuário</p>
          <p>
            Para que possamos oferecer um site melhor e mais direcionado às suas
            preferências, informá-lo sobre alterações relevantes em nossos
            serviços ou relacionadas às suas buscas, processar eventuais pedidos
            e reclamações, compreender suas preferências nas escolhas efetuadas
            por meio do site, sugerir promoções de acordo com seus critérios com
            intuito de tornar sua busca mais próxima aos seus interesses e
            melhorar sua experiência no uso de nossos serviços, é importante
            contarmos com suas informações. Por esse motivo, leia atentamente
            esta política e nossos Termos de Uso.
          </p>
          <p>
            Sua privacidade é nossa preocupação. Temos o compromisso de
            preservar, respeitar e protegê-la.
          </p>
          <p>
            Nossa política de privacidade visa assegurar a garantia de que
            quaisquer informações relativas aos usuários não sejam fornecidas,
            publicadas ou comercializadas em quaisquer circunstâncias, ou seja,
            suas informações estarão seguras conosco e não serão transmitidas a
            terceiros fora do grupo ORION SINAIS, exceto nas condições previstas
            pela presente política de privacidade, precisamente no que tange à
            verificação de sua identidade para fins de segurança, de forma a
            prevenir ou detectar crimes em desfavor do usuário ou do próprio
            grupo ORION SINAIS.
          </p>
          <p>
            A ORION SINAIS poderá alterar esta Política de Privacidade a
            qualquer tempo, sempre com o objetivo de aprimorá-la.
          </p>
          <p>
            Suas informações cadastrais ficam disponibilizadas através de duas
            maneiras: quando você efetua o seu cadastro, registro e adquire
            produtos por meio de nosso site e também através de cookies ou
            arquivos que podem ser livremente alterados pelo usuário na
            configuração de seu navegador e que estão relacionados às suas
            visitas em nossos sites.
          </p>
          <p>
            Suas informações pessoais e seus dados sensíveis não serão
            transferidos a terceiros, ainda que dados objetivos e anônimos
            possam ser considerados para os fins descritos acima.
          </p>
          <p>
            Todos os dados pessoais informados pelo Usuário no momento de seu
            Cadastro são armazenados em servidores ou meios magnéticos de alta
            segurança.
          </p>
          <p>
            A ORION SINAIS CURSOS E PALESTRAS LTDA adota todas as medidas
            possíveis para manter a confidencialidade e a segurança das
            informações prestadas por seus USUÁRIOS. No entanto, os USUÁRIOS
            declaram-se cientes da possibilidade de violação da segurança dos
            servidores do SITE por ataques de hackers (cyber criminosos) ou
            outras circunstâncias a que sistemas conectados à Internet estejam
            sujeitos, casos em que, apesar dos melhores esforços da ORION
            SINAIS, as informações e dados dos USUÁRIOS poderão eventualmente
            ser ilegalmente acessadas por terceiros.
          </p>
          <p>
            A ORION SINAIS CURSOS E PALESTRAS LTDA poderá também revelar dados e
            informações de qualquer USUÁRIO(S), caso isso seja requerido por lei
            ou por ordem de uma autoridade competente.
          </p>
          <p className="bold">Cadastro</p>
          <p>
            O ORION SINAIS nunca envia e-mails solicitando confirmação de
            dados/cadastro ou com anexos executáveis (extensão exe, com, scr,
            bat) e links para download.
          </p>
          <p>
            Para usufruir dos benefícios adicionais do site e receber o e-mail
            com Ofertas Exclusivas e informações sobre nossos serviços é
            necessário possuir cadastro na ORION SINAIS. Este cadastro é
            armazenado em um banco de dados protegido e sigiloso. Qualquer
            comunicação enviada para seu e-mail será através do boletim
            periódico do ORION SINAIS. Seu e-mail não será divulgado.
          </p>
          <p>
            Caso você não queira mais receber comunicados diretos do ORION
            SINAIS, poderá clicar na opção "opt-out", contida em todos os
            comunicados enviados pelo ORION SINAIS.
          </p>
          <p>
            Pode ser que a exclusão e consequente cancelamento não ocorram de
            maneira imediata devido a manutenção e/ou atualização dos servidores
            ativos. Contudo, caso o encerramento demore mais que o esperado para
            se efetivar, o usuário poderá entrar em contato diretamente com a
            ORION SINAIS informando sua necessidade através do e-mail
            contato@orionsinais.com.br, ou através de notificação enviada para o
            endereço indicado no final da presente Política.
          </p>
          <p>
            Suas informações pessoais serão utilizadas apenas para os propósitos
            para os quais elas nos foram fornecidas.
          </p>
          <p className="bold">Cookies</p>
          <p>
            Os Cookies são informações enviadas pelo servidor da ORION SINAIS ao
            computador do usuário, que auxilia na identificação de seu acesso e
            na facilidade e eficiência de sua navegação, simplificando o login
            de usuários cadastrados, auxiliando na garantia de segurança e
            autenticidade dos acessos, bem como para controle interno de
            audiência e de preferências. A aceitação ou não dos cookies pode ser
            livremente alterada na configuração de seu navegador. No entanto,
            quando o usuário desrespeitar alguma regra de segurança ou exercer
            alguma atividade prejudicial ao bom funcionamento do site, como por
            exemplo, tentativas de hackear o serviço, a ORION SINAIS, para
            proteção e segurança do site e dos demais usuários, pode adotar
            medidas para identificar o usuário e coibir tais condutas ilícitas.
          </p>
          <p>
            O menu de ajuda na barra de seu navegador poderá auxiliá-lo a evitar
            a inserção de novos cookies ou desativá-los. No entanto, caso não
            saiba como alterar suas configurações de privacidade, entre em
            contato conosco pelo endereço informado ao final desta Política e
            poderemos auxiliá-lo.
          </p>
          <p className="bold">Compartilhamento de informações</p>
          <p>
            Como já informamos, para fins de segurança, as informações poderão
            ser compartilhadas quando houver requerimento de autoridades
            judiciais ou governamentais competentes para fins de investigações
            pessoais conduzidas por elas, mesmo que não exista uma ordem
            judicial, por exemplo (e sem limitar-se a) quando tratar-se de
            investigação de caráter penal ou a violação de direitos do autor,
            ressalvadas as hipóteses de sigilo de informações determinadas por
            leis em vigor, ou ainda, comunicar informações quando haja motivos
            suficientes para considerar que a atividade de um usuário seja
            suspeita de tentar ou de cometer um delito ou tentar prejudicar
            outras pessoas. Este direito será utilizado pela ORION SINAIS a sua
            inteira discrição quando o considere apropriado ou necessário para
            manter a integridade e a segurança da comunidade e de seus usuários.
          </p>
          <p>
            Atenção: A ORION SINAIS possui links de acesso para outros sites e
            que não estão cobertos por esta política. Assim, verifique a
            política de privacidade desses sites, tendo em vista que suas
            informações serão administradas de acordo com a política de cada um
            deles e que podem diferenciar das nossas.
          </p>
          <p className="bold">Segurança das Informações</p>
          <p>
            Todos os dados pessoais informados ao nosso site são armazenados em
            um banco de dados reservado e com acesso restrito às pessoas
            habilitadas, que são obrigadas, por contrato, a manter a
            confidencialidade das informações e não utilizá-las inadequadamente.
          </p>
          <p className="bold">Observação para menores de 18 anos</p>
          <p>
            Se você tem menos de 18 anos de idade, não deve enviar dados
            pessoais próprios ou de amigos, nem se inscrever em nenhum dos
            serviços on-line da ORION SINAIS, nem clicar em botões de
            solicitação ou consentimento neste SITE.
          </p>
          <p className="bold">Dúvidas e correção de dados</p>
          <p>
            ORION SINAIS é uma empresa de propriedade da ORION SINAIS CURSOS E
            PALESTRAS LTDA., sociedade empresária limitada, com sede na cidade
            de São Paulo, Estado de São Paulo, na Avenida Paulista, nº 1.636 –
            Conjunto 4 – Pav. 15 - Bairro Bela Vista - CEP 01310-200, inscrita
            no CNPJ/MF sob o nº 54.792.178/0001-41.
          </p>
          <p className="bold">
            Em caso de dúvidas o usuário poderá entrar em contato pelo e-mail
          </p>
          <p>contato@orionsinais.com.br</p>
          <p>
            Gostaríamos de ouvir sua opinião sobre nossos serviços e em algumas
            oportunidades poderemos entrar em contato. No entanto, como já
            afirmamos, caso não tenha interesse em receber nosso contato, basta
            nos informar.
          </p>
          <p>
            Caso tenha interesse em verificar a correção de seus dados, solicite
            uma cópia para contato@orionsinais.com.br. Se algum dos dados
            estiverem incorretos, avise-nos e vamos alterá-los. Esta política
            substitui todas as versões anteriores.
          </p>
          <p>
            Reservamo-nos o direito de alterar a política a qualquer momento.
          </p>
        </div>
      </div>
    </div>
  );
}

export default PrivacyModal;
