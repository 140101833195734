import React, { useEffect, useState } from "react";
import "./subscriptionSecondSection.css";

import QRCode from "react-qr-code";
import {
  ChevronLeftIcon,
  DocumentDuplicateIcon,
} from "@heroicons/react/24/outline";
import orionAPI from "../../../config/orionAPI";
import { useNavigate } from "react-router-dom";
import { ClipLoader } from "react-spinners";

import useUser from "../../../hooks/useUser";

function SubscriptionSecondSection({
  selectedPlan,
  setSelectedPlan,
  setPage,
  page,
  loading,
  setLoading,
  setPaymentConfirmed,
}) {
  const [pixCode, setPixCode] = useState("");
  const [temp, setTemp] = useState(0);
  const [userSubscription, setUserSubscription] = useState();

  const { fetchUser, user } = useUser();

  const navigate = useNavigate();

  const handleReturnPage = () => {
    setPage(page - 1);
    setSelectedPlan({});
  };

  const fetchUserSubscription = async () => {
    await fetchUser().then(setUserSubscription(user.subscription));
  };

  useEffect(() => {
    const i = setInterval(() => {
      setTemp((prevTemp) => prevTemp + 1);
    }, 10000);

    return () => {
      clearInterval(i);
    };
  }, []);

  useEffect(() => {
    fetchUserSubscription();

    if (userSubscription) {
      setPaymentConfirmed(true);
    }
  }, [temp]);

  const copyPixCode = () => {
    navigator.clipboard.writeText(pixCode);
  };

  const getPixCode = (planId) => {
    setLoading(true);
    orionAPI
      .post("api/users/me/subscribe/pix", {
        subscription_plan_id: planId,
      })
      .then(function (response) {
        setPixCode(response.data.data.emv);
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    getPixCode(selectedPlan.id);
  }, []);

  return (
    <div className="orion__second-section">
      <div className="orion__second-section-header">
        <button className="orion__return-button" onClick={handleReturnPage}>
          <ChevronLeftIcon className="icon" />
        </button>
        <div className="orion__header-text">
          <small className="orion__duration-label">
            {selectedPlan.duration_in_days} DIAS
          </small>
          <h1 className="orion__value-label">R${selectedPlan.price}</h1>
        </div>
      </div>
      {loading ? (
        <ClipLoader
          color="#D42A5B"
          size={50}
          aria-label="Loading Spinner"
          data-testid="loader"
          className="orion__loading"
        />
      ) : (
        <>
          <div className="orion__qr-container">
            <QRCode className="orion__qr-code" value={pixCode} />
          </div>
          <div className="orion__pix-code">{pixCode}</div>
          <button className="orion__copy-button" onClick={copyPixCode}>
            <DocumentDuplicateIcon className="orion__copy-icon" />
            <small>COPIAR CÓDIGO</small>
          </button>
        </>
      )}
      <small>
        Por favor, ao efetuar o pagamento, espere de 1 a 2 minutos para a
        confirmação.
      </small>
    </div>
  );
}

export default SubscriptionSecondSection;
