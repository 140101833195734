export const formatCPF = (e, setFormData, setErrors) => {
  if (e.target.value.length < 11) {
    setFormData((prev) => {
      return {
        ...prev,
        cpf: e.target.value,
      };
    });
    setErrors((prev) => {
      return {
        ...prev,
        cpf: "CPF inválido",
      };
    });
  } else {
    let formattedCPF = e.target.value
      .replace(/\D/g, "")
      .replace(/^(\d{3})(\d{3})(\d{3})(\d{2})$/, "$1.$2.$3-$4");

    setFormData((prev) => {
      return {
        ...prev,
        cpf: formattedCPF,
      };
    });
    setErrors((prev) => {
      return {
        ...prev,
        cpf: null,
      };
    });
  }
};
export const formatPhoneNumber = (e, setFormData, setErrors) => {
  setFormData((prev) => {
    return {
      ...prev,
      phone_number: e.target.value,
    };
  });
  if (e.target.value.length < 11) {
    setErrors((prev) => {
      return {
        ...prev,
        phone_number: "Telefone inválido",
      };
    });
  } else {
    let formattedPhoneNumber = e.target.value
      .replace(/\D/g, "")
      .replace(/^(\d{2})(\d{5})(\d{4})$/, "($1)$2-$3");

    setFormData((prev) => {
      return {
        ...prev,
        phone_number: formattedPhoneNumber,
      };
    });
    setErrors((prev) => {
      return {
        ...prev,
        phone_number: null,
      };
    });
  }
};

export const formatCEP = (e, setFormData) => {
  let formattedCEP = e.target.value
    .replace(/\D/g, "")
    .replace(/^(\d{5})(\d{3})$/, "$1-$2");

  setFormData((prev) => {
    return {
      ...prev,
      address: {
        ...prev.address,
        cep: formattedCEP,
      },
    };
  });

  return formattedCEP;
};
