import React from 'react'
import './button.css'

function Button({ text, styleType, type, onClick, children, name }) {
  return (
    <div className='orion__button-container'>
      <button
        className={`orion__button-${styleType}`}
        onClick={onClick}
        type={type}
        name={name}
      >
        {text ? text : children}
      </button>
    </div>
  )
}

export default Button