import React, { useState } from "react";

import { Input } from "../../../../components";
import "./index.css";

import { formatPhoneNumber, formatCPF } from "../../helpers/formatHelpers";
import { isEmailValid } from "../../helpers/validatorHelpers";

import { EyeSlashIcon, EyeIcon } from "@heroicons/react/24/outline";
function PersonalInfo({ formData, setFormData, errors, setErrors }) {
  const [showPassword, setShowPassword] = useState(false);

  const handleGenericFields = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });

    setErrors({
      ...errors,
      [e.target.name]: null,
    });
  };

  return (
    <div className="orion__su-column">
      <h2>Dados pessoais</h2>
      <div className="orion__su-form">
        <div className="orion__double-input">
          <Input
            label="Primeiro nome"
            name="first_name"
            placeholder="John"
            onChange={handleGenericFields}
            value={formData.first_name}
            error={errors?.personalInfoErrors?.first_name || errors?.first_name}
            required
          />
          <Input
            label="Sobrenome"
            name="last_name"
            placeholder="Doe"
            onChange={handleGenericFields}
            value={formData.last_name}
            error={errors?.personalInfoErrors?.last_name || errors?.last_name}
            required
          />
        </div>
        {formData.address.country === "BR" ? (
          <Input
            label="CPF"
            name="cpf"
            placeholder="000.000.000-00"
            onChange={(e) => formatCPF(e, setFormData, setErrors)}
            value={formData.cpf}
            error={errors?.personalInfoErrors?.cpf || errors?.cpf}
            maxLength={14}
            required
          />
        ) : (
          <></>
        )}

        <div className="orion__double-input">
          <Input
            label="Data de nascimento"
            name="birthday"
            type="date"
            placeholder="DD/MM/AAAA"
            onChange={handleGenericFields}
            value={formData.birthday}
            error={errors?.personalInfoErrors?.birthday || errors?.birthday}
            required
          />
          {formData.address.country === "BR" ? (
            <Input
              label="Telefone"
              name="phone_number"
              placeholder="(XX)XXXXX-XXXX"
              onChange={(e) => formatPhoneNumber(e, setFormData, setErrors)}
              value={formData.phone_number}
              error={
                errors?.personalInfoErrors?.phone_number || errors?.phone_number
              }
              maxLength={14}
              required
            />
          ) : (
            <Input
              label="Telefone"
              name="phone_number"
              placeholder="Telefone"
              onChange={handleGenericFields}
              value={formData.phone_number}
              error={
                errors?.personalInfoErrors?.phone_number || errors?.phone_number
              }
              maxLength={25}
              required
            />
          )}
        </div>
        <Input
          label="E-mail"
          name="email"
          placeholder="nome@email.com"
          onChange={(e) => isEmailValid(e, setFormData, setErrors)}
          value={formData.email}
          error={errors?.personalInfoErrors?.email || errors?.email}
          required
        />
        <div className="orion__password-container">
          <Input
            label="Senha"
            name="password"
            type={showPassword ? "text" : "password"}
            placeholder="Senha"
            onChange={handleGenericFields}
            value={formData.password}
            error={errors?.personalInfoErrors?.password || errors?.password}
            required
          />
          <div
            className="orion__password-eye"
            onClick={() => setShowPassword(!showPassword)}
          >
            {showPassword ? <EyeSlashIcon /> : <EyeIcon />}
          </div>
        </div>
        <div className="orion__password-container">
          <Input
            label="Confirme sua senha"
            name="passwordConfirmation"
            type={showPassword ? "text" : "password"}
            placeholder="Senha"
            onChange={handleGenericFields}
            value={formData.passwordConfirmation}
            error={
              errors?.personalInfoErrors?.passwordConfirmation ||
              errors?.passwordConfirmation
            }
            required
          />
        </div>
      </div>
    </div>
  );
}

export default PersonalInfo;
