import React, { useEffect, useState } from "react";
import { LoginSignUpLayout } from "../../layouts";
import "./pendingConfirmation.css";

import { CheckCircleIcon } from "@heroicons/react/24/outline";

import confirmationPending from "../../assets/confirmation-pending.svg";
import orionAPI from "../../config/orionAPI";

import { useLocation } from "react-router-dom";

function PendingConfirmation() {
  const [isEmailResent, setIsEmailResent] = useState(false);
  const location = useLocation();

  const handleEmailResend = () => {
    setIsEmailResent(false);
    orionAPI
      .post("/api/resend_confirmation_email/", {
        email: location.state.email,
      })
      .then(setIsEmailResent(true))
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <LoginSignUpLayout>
      <div className="orion__confirmation-pending">
        <img src={confirmationPending}></img>
        <h1>Confirme seu cadastro</h1>
        <p>
          Enviamos um e-mail com um link de confirmação para{" "}
          {location.state.email}. Clique no link para poder liberar o acesso a
          sua conta.
        </p>
        <small onClick={() => handleEmailResend()}>
          Não recebeu o e-mail? Clique aqui para reenviar.
        </small>
        {isEmailResent ? (
          <div className="orion__email-resent">
            <CheckCircleIcon className="orion__email-resent-icon" />
            <small>E-mail reenviado com sucesso!</small>
          </div>
        ) : (
          <></>
        )}
      </div>
    </LoginSignUpLayout>
  );
}

export default PendingConfirmation;
