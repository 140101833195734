import React from "react";
import "./loginSignUpLayout.css";
import logo from "../../assets/logo-dark.png";

function LoginSignUpLayout({ children }) {
  return (
    <div className="orion__container">
      <div className="orion__modal">
        <div className="orion__logo-container">
          <img className="orion__logo" src={logo} alt="logo"></img>
        </div>
        {children}
      </div>
    </div>
  );
}

export default LoginSignUpLayout;
