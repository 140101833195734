import { formatDate } from "./dateHelpers";

export const filterHistoryData = (historyData, firstDate, finalDate) => {
  firstDate = new Date(firstDate);
  finalDate = new Date(finalDate);

  let filteredData = historyData.filter((data) => {
    let buyDate = new Date(formatDate(data.dataCompra));
    let sellDate;

    if (data.dataVenda) {
      sellDate = new Date(formatDate(data.dataVenda));
    }

    if (!data.dataVenda) {
      return buyDate >= firstDate && buyDate <= finalDate;
    } else {
      return (
        buyDate >= firstDate &&
        buyDate <= finalDate &&
        sellDate >= firstDate &&
        sellDate <= finalDate
      );
    }
  });

  return filteredData;
};
