import React, { useEffect, useState } from 'react'
import './index.css'

const Header = ({ columns }) => {
    return (
        <thead className='orion__column-header'>
            <tr>
                {columns.map(column => <th key={column.key}>{column.name}</th>)}
            </tr>
        </thead>
    )
}

const Content = ({ entries, columns }) => {
    return (
        <tbody className='orion__column-body'>
            {entries.map((entry) => (
                <tr key={entry.dataCompra}>
                    {columns.map((column) => (
                        <Cell key={column.key} column={column} entry={entry} />
                    ))}
                </tr>
            ))}
        </tbody>
    )
}

const Cell = ({ column, entry }) => {
    if (column.key === "percentual") {
        return (
            <td key={column.key} className={(
                Number(entry[column.key].replace("%", "")) < 0 ?
                    "orion__column-negative" : "orion__column-positive"
            )}>{`${entry[column.key]}`.replace('.', ',')}</td>
        )
    } else if (column.key === "valorCompra" || column.key === "valorVenda") {
        return (
            <td key={column.key}>{`${toFixed(entry[column.key])}`.replace('.', ',')}</td>
        )
    } else {
        return (
            <td key={column.key}>{entry[column.key]}</td>
        )
    }
}

function toFixed(x) {
    if (Math.abs(x) < 1.0) {
        var e = parseInt(x.toString().split('e-')[1]);
        if (e) {
            x *= Math.pow(10, e - 1);
            x = '0.' + (new Array(e)).join('0') + x.toString().substring(2);
        }
    } else {
        var e = parseInt(x.toString().split('+')[1]);
        if (e > 20) {
            e -= 20;
            x /= Math.pow(10, e);
            x += (new Array(e + 1)).join('0');
        }
    }
    return x;
}

function HistoryTable({ coinHistoryData }) {
    const columns = [
        {
            "key": "dataCompra",
            "name": "Data da Compra"
        },
        {
            "key": "valorCompra",
            "name": "Valor da Compra"
        },
        {
            "key": "dataVenda",
            "name": "Data da Venda"
        },
        {
            "key": "valorVenda",
            "name": "Valor da Venda"
        },
        {
            "key": "duracao",
            "name": "Duração"
        },
        {
            "key": "percentual",
            "name": "Percentual de Lucro"
        }
    ]

    const [tableData, setTableData] = useState([]);

    const sortTableData = (historyData) => {
        let buyDateA;
        let buyDateB;

        let unsortedTableData = [...historyData];

        let sortedTableData = unsortedTableData.sort((a, b) => {
            buyDateA = new Date(formatDate(a.dataCompra));
            buyDateB = new Date(formatDate(b.dataCompra));

            return buyDateA < buyDateB;
        })

        setTableData(sortedTableData)
    }

    const formatDate = (date) => {
        const dateArray = date.split('/');
        const formattedDate = [];

        formattedDate.push(dateArray[2], "/", dateArray[1], "/", dateArray[0]);

        return formattedDate.join().replaceAll(',', '')
    }

    useEffect(() => {
        if (coinHistoryData) {
            sortTableData(coinHistoryData)
        }
    }, [coinHistoryData])

    return (
        <div className='orion__table-container'>
            <table className='orion__table'>
                <Header columns={columns} />
                <Content entries={tableData} columns={columns} />
            </table>
        </div>
    )
}

export default HistoryTable