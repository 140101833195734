import React, { useEffect, useState } from "react";
import "./index.css";

import { faqQuestions } from "./constants/questions";

function FaqSection() {
  const [currentSection, setCurrentSection] = useState("signup");
  const [currentQuestions, setCurrentQuestions] = useState([]);

  useEffect(() => {
    if (currentSection) {
      const sectionContent = faqQuestions.find(
        (element) => element.section === currentSection
      );

      const { questions } = sectionContent;
      setCurrentQuestions(questions);
    }
  }, [currentSection]);

  const selectMenuItem = (e) => {
    setCurrentSection(e.target.name);
  };

  return (
    <div className="orion__faq">
      <div className="orion__faq-menu">
        <button
          className={
            currentSection === "signup"
              ? "orion__faq-menu-item selected"
              : "orion__faq-menu-item"
          }
          name="signup"
          onClick={selectMenuItem}
        >
          Cadastros e acessos
        </button>
        <button
          className={
            currentSection === "general"
              ? "orion__faq-menu-item selected"
              : "orion__faq-menu-item"
          }
          name="general"
          onClick={selectMenuItem}
        >
          Dúvidas Gerais
        </button>
        <button
          className={
            currentSection === "recommendations"
              ? "orion__faq-menu-item selected"
              : "orion__faq-menu-item"
          }
          name="recommendations"
          onClick={selectMenuItem}
        >
          Recomendações
        </button>
        <button
          className={
            currentSection === "payments"
              ? "orion__faq-menu-item selected"
              : "orion__faq-menu-item"
          }
          name="payments"
          onClick={selectMenuItem}
        >
          Planos e pagamentos
        </button>
      </div>
      <div className="orion__faq-questions">
        {currentQuestions &&
          currentQuestions.map((item, index) => (
            <div className="orion__faq-question">
              <div className="orion__title-wrapper">
                <input
                  type="checkbox"
                  className="orion__title-input"
                  name="question_title"
                  defaultChecked={false}
                />
                <label for="question_title">
                  {index + 1}. {item.question}
                </label>
                <div className="orion__answer">
                  <p className="orion__faq-question-answer">{item.answer}</p>
                </div>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
}

export default FaqSection;
