import React from "react";
import "./datePicker.css";

function DatePicker({ label, date, setDate }) {
  const handleDateChange = (e) => {
    setDate(e.target.value.replaceAll("-", "/"));
  };

  return (
    <div className="orion__datepicker-container">
      <small>{label.toUpperCase()}</small>
      <input
        type="date"
        className="orion__datepicker"
        name="initialDateFilter"
        id="initialDateFilter"
        placeholder="Data Inicial"
        value={date.replaceAll("/", "-")}
        onChange={handleDateChange}
      />
    </div>
  );
}

export default DatePicker;
