import React from "react";
import "./index.css";
import { CityAndStateDropdown } from "../../components";
import { Button } from "../../../../components";
import useCountries from "../../../../hooks/useCountries";

function Introduction({
  selectedCountry,
  setSelectedCountry,
  errors,
  handleSubmit,
}) {
  const countries = [
    {
      cca2: "BR",
      name: {
        common: "Brazil",
      },
    },
    {
      cca2: "US",
      name: {
        common: "United States",
      },
    },
  ];

  return (
    <div className="orion__su-introduction">
      <h1>Cadastro</h1>
      <p>Para começar, insira seu país:</p>
      {countries && (
        <CityAndStateDropdown
          label="país"
          options={countries}
          setData={setSelectedCountry}
          currentSelected={selectedCountry}
          error={errors?.country}
        />
      )}
      <Button styleType="filled" onClick={handleSubmit} text={"Continuar"} />
    </div>
  );
}

export default Introduction;
