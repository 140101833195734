import React, { useState } from 'react'
import './resendConfirmationEmail.css'

import { LoginSignUpLayout } from '../../layouts'
import { Button, Input } from '../../components'
import orionAPI from '../../config/orionAPI';
import { ClipLoader } from 'react-spinners';

import { useNavigate } from 'react-router-dom';

function ResendConfirmationEmail() {
    const [email, setEmail] = useState();
    const [loading, setLoading] = useState();
    const [error, setError] = useState();

    const navigate = useNavigate();

    const handleResendConfirmationEmail = async (event) => {
        event.preventDefault()

        const validator = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;

        if (validator.test(email.toLowerCase())) {
            await orionAPI
                .post('/api/resend_confirmation_email/', {
                    email: email
                })
                .then((response) => {
                    navigate('/confirmacao-pendente', { replace: true, state: { email: email } })
                })
        } else {
            setError('Digite um e-mail válido')
        }

    }

    return (
        <LoginSignUpLayout>
            <h1>Re-enviar e-mail de confirmação</h1>
            <form
                className='orion__request-resend-confirmation-email'
                onSubmit={handleResendConfirmationEmail}
            >
                <Input
                    label="Digite seu e-mail"
                    name="email"
                    placeholder="bob@email.com"
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}
                    error={error}
                    required
                />
                {loading
                    ? <Button styleType='filled'>
                        <ClipLoader
                            color='#ffffff'
                            size={20}
                            aria-label="Loading Spinner"
                            data-testid="loader"
                            className='orion__loading'
                        />
                    </Button>
                    : <Button
                        text='Enviar e-mail de redefinição'
                        styleType='filled'
                        onClick={handleResendConfirmationEmail}
                    />
                }
            </form>
        </LoginSignUpLayout>
    )
}

export default ResendConfirmationEmail