import React, { useState } from "react";
import "./setNewPassword.css";
import { Input, Button } from "../../../components";
import { ClipLoader } from "react-spinners";
import orionAPI from "../../../config/orionAPI";

function SetNewPassword({ passwordResetData, setIsPasswordChanged }) {
  const [passwordData, setPasswordData] = useState({
    newPassword: "",
    passwordConfirmation: "",
  });

  const [loading, setLoading] = useState(false);

  const [errors, setErrors] = useState({});
  const [isPasswordsNotMatching, setIsPasswordsNotMatching] = useState(false);

  const handleNewPasswordRequest = (event) => {
    event.preventDefault();
    if (passwordData.newPassword === passwordData.passwordConfirmation) {
      setIsPasswordsNotMatching(false);
      sendResetPasswordRequest();
    } else if (passwordData.newPassword !== passwordData.passwordConfirmation) {
      setIsPasswordsNotMatching(true);
    }
  };

  const sendResetPasswordRequest = async () => {
    setLoading(true);
    await orionAPI
      .post("/api/reset_password/", {
        ...passwordResetData,
        password: passwordData.newPassword,
      })
      .then((response) => {
        setIsPasswordChanged(true);
        setLoading(false);
      })
      .catch((error) => {
        setErrors((prev) => {
          return {
            ...prev,
            password: error.response.data.errors.password,
          };
        });
        setLoading(false);
      });
  };

  return (
    <div className="orion__set-new-password-container">
      <h1>Redefinir senha</h1>
      <form
        className="orion__set-new-password-form"
        onSubmit={handleNewPasswordRequest}
      >
        <Input
          label="Nova senha"
          type="password"
          name="password"
          placeholder="Senha"
          onChange={(e) =>
            setPasswordData((prev) => {
              return {
                ...prev,
                newPassword: e.target.value,
              };
            })
          }
          value={passwordData.newPassword}
          error={errors.password}
          required
        />
        <Input
          label="Confirme sua senha"
          type="password"
          name="confirm-password"
          placeholder="Confirmar senha"
          onChange={(e) =>
            setPasswordData((prev) => {
              return {
                ...prev,
                passwordConfirmation: e.target.value,
              };
            })
          }
          value={passwordData.passwordConfirmation}
          error={isPasswordsNotMatching ? "As senhas não coincidem" : ""}
          required
        />
        {loading ? (
          <Button styleType="filled">
            <ClipLoader
              color="#ffffff"
              size={20}
              aria-label="Loading Spinner"
              data-testid="loader"
              className="orion__loading"
            />
          </Button>
        ) : (
          <Button
            text="Alterar a senha"
            styleType="filled"
            onClick={handleNewPasswordRequest}
          />
        )}
      </form>
    </div>
  );
}

export default SetNewPassword;
