import React from "react";
import "./useTermsModal.css";

import { XMarkIcon } from "@heroicons/react/24/outline";

function UseTermsModal({ setOpenModal }) {
  return (
    <div className="modalBackground">
      <div className="modalContainer">
        <div className="headerWrapper">
          <div className="title">
            <h1>Termos de Uso</h1>
          </div>
          <div className="titleCloseBtn">
            <button
              onClick={() => {
                setOpenModal(false);
              }}
            >
              <XMarkIcon className="modal-icon" />
            </button>
          </div>
        </div>

        <div className="body">
          <p className="bold">Informações do Usuário</p>
          <p>
            Termo de uso ORION CURSOS E PALESTRAS ONLINE LTDA., inscrita no CNPJ
            sob no 54.792.178/0001-41, com sede na na cidade de São Paulo,
            Estado de São Paulo, na Avenida Paulista, nº 1.636 – Conjunto 4 –
            Pav. 15 - Bairro Bela Vista - CEP 01310-200, única e exclusiva
            proprietária dos domínios que utilizam a marca ORION SINAIS, como:
            "https://www.orionsinais.com.br", "https://www.orionsinais.com" e
            doravante denominado unicamente de SITE ORION SINAIS, estabelece o
            presente instrumento, denominado TERMO DE USO para o USUÁRIO do SITE
            ORION SINAIS conforme as condições abaixo descritas: Por este TERMO
            DE USO, o USUÁRIO do ORION SINAIS fica ciente e concorda que ao
            utilizar o sistema do ORION SINAIS, automaticamente adere e concorda
            em se submeter integralmente às condições do presente TERMO DE USO e
            qualquer de suas alterações futuras visando seu aprimoramento e à
            melhoria dos serviços oferecidos pelo SITE. Caso o USUÁRIO não
            concorde com quaisquer alterações implementadas nestes Termos de
            Uso, deverá imediatamente cancelar seu cadastro e se não o efetuar
            estará concordando com os novos Termos de Uso. As palavras grafadas
            neste instrumento com letras maiúsculas terão o significado que a
            elas é atribuído de acordo com o estabelecido abaixo: USUÁRIO(S):
            são as pessoas físicas ou jurídicas que acessam e usam os serviços
            providos pela ORION SINAIS. SITE: conjunto de páginas ou lugar no
            ambiente da Internet ocupado com CONTEÚDO de uma empresa ou de uma
            pessoa. SITE ORION SINAIS: é o SITE de internet registrado no Núcleo
            de Informação e Coordenação do Ponto BR (www.registro.br) sob o
            domínio orionsinais.com.br, com todos os recursos e ferramentas
            relacionadas a este, bem como outros SITES e domínios da marca ORION
            SINAIS registrados nos respectivos órgãos responsáveis pela
            classificação e registro do domínio. O site oferece a seus
            USUÁRIO(S) uma plataforma para comparar cotação(ões) de moeda(s)
            estrangeira(s) e identificar serviço(s) de âmbito do câmbio de
            moeda(s) estrangeira(s). O SITE ORION SINAIS não compra, vende ou
            presta serviço(s) de câmbio, sendo responsável pela(s)
            negociação(ões) o(s) ANUNCIANTE(S) da plataforma sob rigor da
            legislação vigente. O SITE ORION SINAIS não é parte nem deverá ser
            considerada parte em qualquer das transações de compra e venda
            realizadas pelos USUÁRIO(S) e não será responsável pelo efetivo
            cumprimento das obrigações assumidas pelos USUÁRIO(S) nessas
            transações. ANUNCIANTE(S): são as pessoas físicas ou jurídicas que
            veiculam seus anúncios ou publicidade no ORION SINAIS. COOKIE(S):
            pequeno arquivo de texto armazenado nos navegadores. Este recurso
            possibilita a identificação de USUÁRIOS e o direcionamento de
            documentos de acordo com parâmetros pré-estabelecidos. LINK(S):
            significa um acesso eletrônico, seja por meio de imagens ou
            palavras, que permite a conexão a outras telas de um mesmo SITE, ou,
            ainda, de outros SITES. SPAM: são e-mails enviados a um grupo de
            USUÁRIOS sem a sua devida permissão ou consentimento. CONTEÚDO:
            inclui texto, programas, scripts, gráficos, fotos, sons, imagens,
            músicas, vídeos, combinações audiovisuais, animações, recursos
            interativos e outros materiais que o(s) USUÁRIO(S) e o(s)
            ANUNCIANTE(S), têm acesso ou submetem a um SITE. Este documento
            informa as responsabilidades, deveres e obrigações que todo
            USUÁRIO(S) assume ao acessar e/ou utilizar o SITE ORION SINAIS e
            seus serviços relacionados como softwares, complementos, plug-ins,
            barras e acessórios a não ser quando regido por um TERMO DE USO
            específico. SITE: As funcionalidades adicionais do ORION SINAIS,
            possíveis graças à tecnologia eletrônica, não desvirtuam o fato de o
            SITE ORION SINAIS adicionar em seu meio eletrônico informações de
            ANUNCIANTE(S) pelas quais o SITE ORION SINAIS, NÃO se responsabiliza
            (assim como anúncios classificados em jornais, rádio, ou televisão).
            Desse modo, o USUÁRIO(S) deve checar a veracidade das informações
            obtidas pelo meio eletrônico do SITE ORION SINAIS e tomar todas as
            medidas necessárias para se proteger de danos, fraudes ou
            estelionatos, inclusive "on-line" e "off-line". O USUÁRIO(S) deve
            ler com atenção os termos abaixo antes de acessar ou usar o SITE
            ORION SINAIS, pois, o acesso ou uso deste implica em concordância
            integral com tais termos universais. O SITE ORION SINAIS e os demais
            participantes do SITE somente fornecerão ao USUÁRIO(S) informações e
            serviços mediante expressa concordância aos TERMOS DE USO, condições
            e informações aqui contidas, assim como aos demais documentos
            incorporados ao mesmo por referência. O uso do SITE ORION SINAIS
            significa a total concordância com tais termos, condições e
            informações. A ORION SINAIS captura e divulga anúncios/ofertas
            advindos de diversos ANUNCIANTE(S), logo, não atua como prestador de
            serviços de consultoria ou ainda intermediário ou participante em
            nenhum negócio jurídico entre o USUÁRIO(S) e o(s) ANUNCIANTE(S). A
            ORION SINAIS oferece em seu SITE um mecanismo de busca personalizada
            de produtos e serviços anunciados e oferecidos através da Internet,
            a fim de selecionar os SITES e ANUNCIANTES que contém os produtos
            e/ou serviços previamente especificado pelo USUÁRIO(S) como sendo
            produtos e/ou serviços de seu interesse a critério de comparação
            gratuita de condições de venda ou compra para seus USUÁRIO(S). O
            SITE ORION SINAIS não assume qualquer responsabilidade que advenha
            das relações existentes entre o USUÁRIO(S) e o(s) ANUNCIANTE(S),
            sejam elas diretas ou indiretas, e/ou também; Pelo CONTEÚDO e
            funcionamento dos SITES das lojas "on-line" ANUNCIANTE(S); Por
            negociações efetuadas entre USUÁRIO(S) e ANUNCIANTE(S); Pela entrega
            de produtos e serviços dos ANUNCIANTE(S), seja por atraso, falha,
            retenção alfandegária, ou qualquer outra razão, sendo tais
            negociações por conta e risco do USUÁRIO(S), que deve cercar-se de
            todos os cuidados necessários; Por eventual variação cambial, para
            produtos ou serviços negociados com ANUNCIANTES do exterior. Nesses
            casos, o preço mostrado pela ORION SINAIS é uma mera estimativa e
            pode sofrer alteração de acordo com o fechamento do câmbio na data
            do pagamento. Por cobranças de impostos ou taxas sobre produtos ou
            serviços adquiridos. O USUÁRIO(S) que opte por negociar com
            ANUNCIANTE(S) do exterior está ciente de que estará sujeito a
            cobrança de impostos ou taxas, de acordo com a legislação vigente no
            Brasil, conforme descrito no site da Receita Federal Brasileira:
            https://www.receita.fazenda.gov.br O USUÁRIO(S) declara ter ciência
            de como funciona a importação de produtos e que a relação jurídica é
            estabelecida diretamente com a ANUNCIANTE(S) e não com o SITE ORION
            SINAIS. Portanto, o SITE ORION SINAIS não é responsável por qualquer
            ação ou omissão do USUÁRIO(S) baseadas nas informações, anúncios,
            vídeos, fotos ou outros materiais veiculados pelo ORION SINAIS.
            Adicionalmente, o SITE ORION SINAIS aplicará seus melhores esforços
            para manter o SITE sempre atualizado, completo e preciso, mas não se
            responsabilizará por erro, fraude, inexatidão, imprecisão ou
            divergência nos dados, vídeos, fotos ou outros materiais
            relacionados a anúncios ou à inexatidão das informações contidas e
            apresentadas no SITE ORION SINAIS. Os serviços do SITE ORION SINAIS
            estão disponíveis para acesso exclusivamente através da Internet.
            Para acessar nossos serviços, o(s) USUÁRIO(S) deverão estar providos
            dos equipamentos necessários e apropriados, tais como computador com
            programa de navegação (browser) devidamente instalado e licenciado,
            modem, bem como dos serviços de provimento e infraestrutura de
            acesso à Internet; Os serviços do SITE ORION SINAIS estão sujeitos a
            interrupções, atrasos e problemas inerentes ao uso da Internet. O
            SITE ORION SINAIS não será responsável por qualquer dessas
            interrupções, atrasos ou problemas, nem por eventuais defeitos ou
            limitações dos equipamentos ou do programa de navegação utilizados
            pelo(s) USUÁRIO(S) ou, ainda, por eventuais defeitos ou limitações
            dos serviços de provimento de acesso ou infraestrutura de acesso à
            Internet contratados pelo(s) USUÁRIO(S); O SITE ORION SINAIS também
            não será responsável por qualquer vírus que possa atacar, danificar
            ou prejudicar o equipamento do(s) USUÁRIO(S) em decorrência do
            acesso, utilização ou navegação na Internet ou como consequência da
            transferência de dados, arquivos, imagens, textos ou áudio.
            Eventuais erros no funcionamento do SITE ORION SINAIS serão
            corrigidos durante o período que for necessário para manutenção. O
            SITE ORION SINAIS não se responsabiliza por danos decorrentes da não
            disponibilidade ou erro de funcionamento do SITE ORION SINAIS. O
            SITE ORION SINAIS, seus funcionários, agentes, colaboradores,
            representantes e procuradores, devem ser eximidos de toda e qualquer
            responsabilidade decorrente de qualquer tipo de reclamação ou ação
            legal contra um ou mais ANUNCIANTE(S), sejam elas promovidas por um
            ou mais USUÁRIO(S), ou inclusive por algum terceiro. Também não
            serão de responsabilidade do SITE ORION SINAIS as obrigações
            tributárias que recaiam nas atividades entre USUÁRIO(S) e
            ANUNCIANTE(S) no SITE ORION SINAIS. Assim, ao adquirir algum bem ou
            serviço, o(s) USUÁRIO(S) consumidor deverá exigir documento fiscal
            do ANUNCIANTE(S), a menos que este esteja legalmente dispensado de
            fornecê-la. Toda transação realizada pela Internet deve ocorrer com
            toda cautela e bom senso redobrados. Logo, o(s) USUÁRIO(S) deverá
            avaliar os riscos das negociações, levando em consideração que pode,
            eventualmente, lidar com pessoas valendo-se de falsas identidades ou
            ainda com menores de idade. O(s) USUÁRIO(S) reconhecem e concordam
            que o SITE ORION SINAIS é a única e exclusiva titular de todos os
            direitos autorais e de propriedade intelectual relacionados ao SITE,
            incluindo seu nome de domínio, sua programação, arquivos, bancos de
            dados, conteúdo, design, funcionalidades e demais características e
            que tais direitos são protegidos por lei. O nome comercial ORION
            SINAIS, a marca ORION SINAIS e os produtos e serviços associados a
            essa marca são de exclusiva titularidade da ORION SINAIS INFORMAÇÃO
            E SERVIÇOS DIGITAIS LTDA e seu uso não autorizado está sujeito às
            consequências previstas em lei. Os presentes Termos de Uso não
            implicam a cessão ou transferência ao(s) USUÁRIO(S) de quaisquer
            direitos relativos ao SITE, ou a qualquer parte de seu conteúdo ou
            características. O uso do SITE é apenas nos estritos termos
            permitidos no presente Termos de Uso. A ORION SINAIS recomenda ao
            USUÁRIO(S) que: Verifique com toda cautela a documentação e/ou
            características de qualquer dos produtos e/ou serviços ofertados
            antes da conclusão de qualquer negócio, concordando que ao negociar
            com o(s) ANUNCIANTE(S) dos produtos e/ou serviços ofertados o fará
            por sua única conta e risco; Seja cuidadoso com os dados de sua
            identificação individual sempre que acessar a Internet,
            informando-os apenas em operações em que existam proteção de dados;
            Tome quaisquer outras medidas necessárias para se proteger de danos,
            inclusive fraudes ou estelionato "on-line"; Cumpra rigorosamente
            todas as determinações deste TERMO DE USO. O(s) USUÁRIO(S) através
            deste TERMO DE USO aceita ser identificado pelo sistema do SITE
            ORION SINAIS, como, por exemplo, no uso de COOKIES e/ou de outras
            tecnologias. Tal política visa à melhoria contínua dos serviços
            prestados pelo SITE ORION SINAIS. Caso exista alguma dúvida
            referente aos dados coletados pelo SITE ORION SINAIS e/ou para obter
            maiores informações sobre a proteção de seus dados pessoais,
            consulte a nossa Política de Privacidade disponível em
            https://www.orionsinais.com.br/privacidade. Caso o USUÁRIO(S) acesse
            o SITE de parceiros, patrocinadores, ANUNCIANTE(S) e demais SITES ou
            serviços que sejam acessados através do SITE ORION SINAIS, é
            possível que haja solicitação de informações financeiras e/ou
            pessoais do consumidor USUÁRIO(S). Tais informações não serão
            enviadas pelo USUÁRIO(S) ao SITE ORION SINAIS, e sim diretamente ao
            solicitante, não tendo o SITE ORION SINAIS, portanto, qualquer
            responsabilidade pela utilização e manejo dessa informação. O SITE
            ORION SINAIS aplicará seus melhores esforços para evitar o envio não
            solicitado de e-mails. Para tanto, resta estabelecido que seja
            absolutamente proibida a utilização do SITE ORION SINAIS para a
            distribuição de SPAM ou o envio indiscriminado de mensagens de
            qualquer natureza via correio eletrônico. Fica a critério exclusivo
            do SITE ORION SINAIS suspender e/ou inabilitar definitivamente e
            aplicar as medidas jurídicas cabíveis aos USUÁRIO(S) que se
            utilizarem do SITE ORION SINAIS com a finalidade de promover seus
            produtos ou serviços à venda ou com qualquer outro fim, e que forem
            denunciados pelas pessoas que receberam essas mensagens SPAM. Para a
            obtenção de informações sobre um anúncio, o(s) USUÁRIO(S) do SITE
            ORION SINAIS não terá que desembolsar qualquer taxa para o(s)
            ANUNCIANTE(S), a que título seja. Qualquer infração nesse sentido
            por parte do ANUNCIANTE(S) deve ser imediatamente notificada ao SITE
            ORION SINAIS. Os materiais do SITE ORION SINAIS poderão ser
            acessados, utilizados e impressos para o uso pessoal e não comercial
            do USUÁRIO(S), logo, o USUÁRIO(S) não poderá copiar, modificar,
            criar trabalho derivado, transferir ou vender qualquer informação,
            "software", lista de USUÁRIO(S) e outras listas, produtos ou
            serviços obtidos no SITE ORION SINAIS para uso comercial. Além
            disso, o USUÁRIO não poderá: Transmitir ou enviar informações de
            qualquer natureza que possam incitar induzir, ou promover atitudes
            discriminatórias, mensagens violentas ou delituosas que atentem
            contra aos bons costumes, à moral ou ainda que contrariem a ordem
            pública; Cadastrar-se com informações de propriedade de terceiros ou
            falsas; Utilizar os produtos e/ou serviços disponíveis no SITE ORION
            SINAIS para fins diversos daqueles a que se destinam; Alterar,
            apagar e/ou corromper dados e informações de terceiros; Violar a
            privacidade de quaisquer USUÁRIO(S) e/ou ANUNCIANTE(S); Violar
            propriedade intelectual do SITE ORION SINAIS, de terceiros em geral,
            ou seja, direito autoral, marca, patente, etc., através de
            reprodução de material, sem a prévia autorização do proprietário;
            Usar "nome de USUÁRIO" que guarde semelhança ou referência ao nome
            "ORION SINAIS"; Cadastros efetuados com “nome de USUÁRIO(S)”
            considerados ofensivos, impróprios, racistas, envergonhados ou de
            teor e/ou conteúdo duvidoso poderão ser cancelados a qualquer
            momento sem aviso prévio do SITE ORION SINAIS; O “nome de USUÁRIO” e
            senha são pessoais e intransferíveis e não deverão ser informados
            pelo USUÁRIO a quaisquer terceiros. Caso o USUÁRIO o tenha
            conhecimento de que qualquer terceiro teve acesso a seu “nome de
            USUÁRIO” e senha, ou da ocorrência de qualquer uso ou acesso não
            autorizado de sua conta no SITE ORION SINAIS por terceiros, o
            USUÁRIO deverá solicitar imediatamente a alteração de senha no
            próprio SITE ORION SINAIS, através da opção “Mudar Senha”, no menu
            “Configurações”, e comunicar o fato à ORION SINAIS INFORMAÇÃO E
            SERVIÇOS DIGITAIS LTDA através do e-mail:
            contato@orionsinais.com.br; O USUÁRIO será o único responsável pela
            segurança e guarda de seu “nome de USUÁRIO” e senha para acessar o
            SITE ORION SINAIS; Transmitir ou enviar arquivos com vírus de
            computador, com CONTEÚDO invasivo, destrutivo ou que cause dano
            temporário ou permanente nos equipamentos do destinatário e/ou do
            SITE ORION SINAIS; Utilizar nomes e/ou apelidos considerados
            ofensivos, bem como os que contenham dados pessoais de qualquer
            USUÁRIO e/ou ANUNCIANTE. Transmitir ou enviar informações de
            propriedade de terceiros; Usar endereços de computadores, de rede ou
            de correio eletrônico falso; Utilizar qualquer nome e/ou apelido que
            insinue ou sugira que os produtos ou serviços anunciados pertencem
            ao SITE ORION SINAIS ou que fazem parte de promoções suas; Utilizar
            materiais que contenham qualquer vírus, trojans, worms, malwares e
            outros programas de computador que possam causar danos ao site ou ao
            USUÁRIO(S) ou ANUNCIANTE(S) do SITE ORION SINAIS; e, Realizar a
            divulgação de materiais ilegais, agressivos, caluniosos, abusivos,
            difamatórios, discriminatórios, ameaçadores, danosos, invasivos da
            privacidade de terceiros, terroristas, vulgares, obscenos ou ainda
            condenáveis de qualquer tipo ou natureza que sejam prejudiciais a
            menores e à preservação do meio ambiente de algum modo. Anexar URLs,
            endereços de e-mail ou outro material publicitário ou de cunho de
            anúncio e/ou publicidade de terceiros sem prévia autorização do SITE
            ORION SINAIS. O descumprimento às condições, termos e observações
            deste TERMO DE USO dá ao SITE ORION SINAIS o direito de cancelar,
            suspender, excluir e/ou desativar o cadastro ou o material enviado
            pelo USUÁRIO, temporária ou definitivamente, ao seu único e
            exclusivo critério, sem prejuízo das cominações legais pertinentes e
            sem a necessidade de comunicar ao USUÁRIO a ação tomada dentro do
            seu sistema. Todos os materiais do SITE ORION SINAIS, incluindo, mas
            não se limitando a apresentação, "layout", marcas, logotipos,
            sistemas, produtos, denominações de serviços e quaisquer outros
            materiais, inclusive bases de dados, programas, arquivos, imagens,
            vídeos ou materiais de qualquer outra natureza e que tenham
            contratualmente autorizadas suas veiculações no SITE ORION SINAIS
            são protegidas pela legislação de Propriedade Intelectual. São de
            titularidade do SITE ORION SINAIS e de suas afiliadas, clientes ou
            fornecedores os direitos de uso e propriedade delimitando que sua
            reprodução, transmissão e distribuição de tais materiais, não são
            permitidas sem o expresso consentimento por escrito do SITE ORION
            SINAIS ou do respectivo titular, sobretudo para o fim econômico ou
            comercial. Caracterizará como infração da legislação vigente, o uso
            indevido de materiais protegidos por propriedade intelectual
            (direito autoral, marcas comerciais, patentes, etc.) apresentados no
            SITE ORION SINAIS, sujeitando-se o(s) USUÁRIO(S) infrator(es) às
            medidas judiciais cabíveis à espécie, sem prejuízo de eventual
            indenização por perdas e danos e honorários advocatícios ao SITE
            ORION SINAIS, bem como a terceiros que venham a sofrer pelo ato do
            USUÁRIO(S). Os riscos derivados da utilização do SITE ORION SINAIS
            são do USUÁRIO(S), logo, se seu uso resultar na necessidade de
            serviços e/ou reposição de material, equipamento, propriedade ou
            informação do USUÁRIO, o SITE ORION SINAIS e suas afiliadas não
            serão responsáveis por tais custos. Os produtos, informações,
            valores, serviços sistemas e/ou "software", publicados no SITE ORION
            SINAIS podem conter imprecisões e/ou erros de grafia, razão pela
            qual o ORION SINAIS aplicará seus melhores esforços para manter o
            SITE atualizado periodicamente. De nenhum modo a ORION SINAIS será
            responsabilizado por qualquer dano direto, indireto, especial,
            incidental ou de qualquer fato resultante do uso do SITE ORION
            SINAIS, inclusive por quaisquer informações, produtos e/ou serviços
            obtidos através dele ou em decorrência do seu uso. O ORION SINAIS
            contém LINKS para outros SITES operados por terceiros que não o
            ORION SINAIS e/ou suas afiliadas. Esses LINKS são fornecidos somente
            para sua referência, razão pela qual, o ORION SINAIS não tem
            controle sobre esses outros SITES e não se responsabiliza pelo
            CONTEÚDO dos mesmos, sendo que a inclusão desses LINKS no ORION
            SINAIS não implica na aprovação do material ou qualquer associação
            com seus proprietários. Assim, ao acessar e usar esses SITES, suas
            informações, materiais, produtos e/ou serviços, o(s) USUÁRIO(S)
            estará fazendo por sua conta e risco. O mesmo se aplica aos SITES
            que possuam LINKS para o ORION SINAIS, eis que o ORION SINAIS não
            possui controle sobre os mesmos. A Política de Privacidade do ORION
            SINAIS é aplicável ao USUÁRIO(S), quando este acessar o SITE ORION
            SINAIS e pode ser encontrada no seguinte LINK:
            https://www.orionsinais.com.br/privacidade. Desse modo, ao ser
            encaminhado para outro SITE, o USUÁRIO estará sujeito às políticas
            deste SITE, sobre o qual o ORION SINAIS não tem qualquer relação. A
            ORION SINAIS, suas afiliadas e seus fornecedores poderão fazer
            mudanças nos serviços, produtos, informações e quaisquer outros
            materiais da ORION SINAIS, inclusive encerrar suas atividades a
            qualquer momento sem a necessidade de enviar qualquer notificação
            prévia ao(s) USUÁRIO(S) e sem o direito de indenização a qualquer
            USUÁRIO(S) ou ANUNCIANTE(S). A ORION SINAIS poderá alterar,
            modificar e/ou ajustar este TERMO DE USO a qualquer tempo, sendo que
            essas alterações, modificações e/ou ajustes terão efeito somente
            após se tornarem públicos. Desse modo, o(s) USUÁRIO(S) obriga-se a
            rever o TERMO DE USO do ORION SINAIS de tempos em tempos, restando
            claro que o USUÁRIO(S) subordina-se à aceitação do TERMO DE USO
            vigente no momento de seu acesso ou faz uso dos serviços prestados
            pela ORION SINAIS. O(s) USUÁRIO(S) e/ou ANUNCIANTE(S) indenizará a
            ORION SINAIS, suas filiais, empresas controladas, controladoras,
            colaboradores, representantes, administradores e empregados por
            qualquer ação promovida por outros USUÁRIO(S), ANUNCIANTE(S) ou
            terceiros decorrentes de atividades ilegais no ORION SINAIS em razão
            do descumprimento do TERMO DE USO e/ou demais políticas do ORION
            SINAIS; ou ainda pela violação de qualquer lei, direito de
            terceiros, responsabilizando-se por eventuais perdas e danos, além
            de honorários advocatícios. O(s) USUÁRIO(S) e o(s) ANUNCIANTE(S)
            detêm de todos os direitos de propriedade sobre a informação, texto,
            gráfico ou outros materiais que publicar no ORION SINAIS, no
            entanto, o SITE pode reproduzir publicar e distribuir todos os
            arquivos, textos, gráficos integral ou em partes do seu CONTEÚDO
            "on-line" e "off-line". O(s) USUÁRIO(S) e o(s) ANUNCIANTE(S) podem
            editar ou remover o CONTEÚDO do ORION SINAIS a qualquer momento,
            todavia, se o ORION SINAIS tiver realizado a distribuição do
            CONTEÚDO do USUÁRIO(S) e o(s) ANUNCIANTE(S) para outros, não se
            compromete a fazer a alteração do que tiver distribuído. Este TERMO
            DE USO se aplica a todos os ANUNCIANTE(S) do ORION SINAIS, inclusive
            os USUÁRIO(S) que também contribuem com CONTEÚDO do ORION SINAIS. Ao
            contribuir/submeter qualquer CONTEÚDO no SITE ORION SINAIS, o(s)
            USUÁRIO(S) e o(s) ANUNCIANTE(S), garantem que possuem as licenças
            necessárias, direitos, autorizações e permissões para publicar o
            CONTEÚDO enviado ao ORION SINAIS. O USUÁRIO(S) e ANUNCIANTE(S)
            concedem ao ORION SINAIS uma licença mundial, perpétua, irrevogável,
            livre de royalties, transferível, bem como e inclusive o direito de
            licença para usar, copiar, modificar, apagar na sua totalidade,
            adaptar, publicar, traduzir, criar trabalhos derivados, vender,
            distribuir materiais e/ou incorporar esses materiais em qualquer
            formato, meio ou tecnologia sem qualquer prejuízo ao USUÁRIO(S) e
            o(s) ANUNCIANTE(S). Desta forma, nenhum dos materiais deve ser
            sujeito/submetido a qualquer obrigação de confiança por parte da
            ORION SINAIS, seus funcionários, agentes, colaboradores e
            representantes sem os direitos ou licenças disponíveis para isso. A
            ORION SINAIS reserva-se ao direito de alterar, condensar ou excluir
            qualquer CONTEÚDO do SITE ORION SINAIS que considere, a seu
            exclusivo critério, violar as diretrizes de CONTEÚDO, a legislação
            brasileira ou de qualquer outra disposição do presente TERMO DE USO.
            O USUÁRIO pode enviar vídeos e comentários, todavia, a ORION SINAIS
            não garante a confidencialidade em relação a qualquer CONTEÚDO
            enviado, sendo importante mencionar que o USUÁRIO é o único
            responsável por seu CONTEÚDO e pelas conseqüências de enviá-lo ou
            publicá-lo, garantindo que possui ou tem as licenças necessárias,
            direitos, autorizações e permissões para publicá-lo. O USUÁRIO
            autoriza a ORION SINAIS a usar todas as patentes, marcas
            registradas, segredos de negócio, direitos autorais ou outros
            direitos de propriedade e tais CONTEÚDOS para a publicação de acordo
            com este TERMO DE USO. Assim, cumpre esclarecer que o USUÁRIO mantém
            todos os direitos de propriedade sobre seu CONTEÚDO, entretanto, ao
            enviar o CONTEÚDO ao ORION SINAIS, o USUÁRIO(S) pelo presente, cede
            ao SITE ORION SINAIS licença mundial, não exclusiva, isenta de
            royalties, passível de ser sublicenciada e transferida, para usar,
            reproduzir, distribuir, preparar trabalhos derivados, exibir e
            executar o CONTEÚDO em conexão com o ORION SINAIS (e de seus
            sucessores e afiliados), inclusive, mas sem se limitar a atividades
            de promoção e redistribuição parcial ou total do Serviço (e
            trabalhos derivados) em qualquer formato de mídia e através de
            qualquer canal de mídia. O USUÁRIO também cede a todos os USUÁRIOS
            do ORION SINAIS uma licença não-exclusiva para acessar o seu
            CONTEÚDO por meio do ORION SINAIS (e de seus sucessores e
            afiliados), e para usar, reproduzir, distribuir, exibir e executar
            tal CONTEÚDO conforme permitido pela funcionalidade do SITE ORION
            SINAIS e de acordo com o TERMO DE USO. As licenças acima cedidas
            pelo(s) USUÁRIO(S) para o SITE ORION SINAIS, irão encerrar dentro de
            um tempo comercialmente razoável após a remoção ou exclusão pelo
            USUÁRIO(S). As licenças acima, cedidas pelo(s) USUÁRIO(S) em relação
            aos comentários são permanentes e irrevogáveis. O USUÁRIO afirma que
            não enviará material protegido por direitos autorais, por segredo de
            negócio ou de qualquer outra forma protegido por concedem ao
            direitos de terceiros, a menos que tenha permissão do legítimo
            proprietário do material ou caso esteja legalmente autorizado a
            publicar o material e ceder ao SITE ORION SINAIS todos os direitos
            de licença aqui concedidos. A ORION SINAIS não endossa qualquer
            CONTEÚDO enviado por qualquer USUÁRIO(S) ou licenciante ou qualquer
            opinião, recomendação ou conselho ali expresso. A ORION SINAIS
            renuncia expressamente toda e qualquer responsabilidade ligada ao
            CONTEÚDO. A ORION SINAIS não permite ou compactua com quaisquer
            atividades que violem os direitos autorais, nem os direitos de
            propriedade intelectual em seu SITE, assim, o SITE ORION SINAIS
            removerá todo o CONTEÚDO quando devidamente notificado de que tal
            CONTEÚDO infringe o direito de propriedade intelectual de qualquer
            outra pessoa. O SITE ORION SINAIS se reserva no direito de remover o
            CONTEÚDO sem prévio aviso. Quaisquer avisos ou comunicações, que uma
            das partes tenha de fazer a outra parte, em relação a quaisquer
            direitos ou obrigações contidas no presente instrumento, deverão
            adotar a forma escrita e serão tidas como eficazmente entregues no
            endereço constante do preâmbulo deste termo, se: Entregues em mãos
            mediante protocolo escrito por quem de direito possa representar a
            parte remetida; Enviadas por serviço de "courier" mediante protocolo
            escrito ou se pelo correio, na forma de carta registrada ("AR"); ou
            Enviadas por e-mail, ou outro meio eletrônico hábil para tais
            comunicações. As partes convencionam o recebimento de mensagens e
            arquivos eletrônicos como prova documental para todos os efeitos,
            desde que com aviso de confirmação de recebimento ou outro tipo de
            protocolo que certifique o recebimento da comunicação. Os presentes
            Termos de Uso são regidos pelas leis da República Federativa do
            Brasil e fica eleito o foro da Comarca de São Paulo, Estado de São
            Paulo, como competente para dirimir quaisquer controvérsias
            decorrentes deste TERMO DE USO independentemente de qualquer outro,
            por mais privilegiado que seja ou venha a ser, para dirimir
            quaisquer litígios ou controvérsias oriundas dos presentes Termos de
            Uso. DISPOSIÇÕES FINAIS No caso de descumprimento, pelo USUÁRIO, de
            qualquer disposição dos presentes Termos de Uso, o SITE ORION SINAIS
            poderá declará-lo resolvido em relação a esse USUÁRIO,
            independentemente de qualquer aviso, notificação ou de qualquer
            outra formalidade, interrompendo, de imediato, o acesso do USUÁRIO
            ao SITE, sem prejuízo de quaisquer outros direitos assegurados à
            ORION SINAIS CURSOS E PALESTRAS LTDA por lei ou pelos presentes
            Termos de Uso. Parte integrante e inseparável deste Termo de Uso e
            incorporado: - Política de Privacidade
            (https://www.orionsinais.com.br/privacidade) O USUÁRIO RECONHECE QUE
            AS PECULIARIDADES DE USO DO SITE E DOS SERVIÇOS NELE
            DISPONIBILIZADOS FORAM SUFICIENTEMENTE DESCRITAS NESTE TERMO E QUE
            ORION SINAIS INFORMAÇÃO E SERVIÇOS DIGITAIS LTDA CUMPRIU DEVIDAMENTE
            O DEVER DE INFORMAÇÃO. APÓS LER TODAS AS CONDIÇÕES QUE REGULAM O USO
            DO SITE E DE SEUS SERVIÇOS, O USUÁRIO CONSENTE COM ESTES TERMOS DE
            USO E ACEITA TODAS AS SUAS DISPOSIÇÕES.
          </p>
        </div>
      </div>
    </div>
  );
}

export default UseTermsModal;
