import React from "react";
import "./index.css";
import { ClipLoader } from "react-spinners";

function LoadingScreen() {
  return (
    <div className="orion__loading-screen">
      <ClipLoader
        color="#D42A5B"
        size={50}
        aria-label="Loading Spinner"
        data-testid="loader"
        className="orion__loading"
      />
    </div>
  );
}

export default LoadingScreen;
