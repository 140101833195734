export const isEmailValid = (e, setFormData, setErrors) => {
  setFormData((prev) => {
    return {
      ...prev,
      email: e.target.value,
    };
  });
  if (e.target.value.includes("@") && e.target.value.includes(".")) {
    setErrors((prev) => {
      return {
        ...prev,
        email: null,
      };
    });
  } else {
    setErrors((prev) => {
      return {
        ...prev,
        email: "Insira um e-mail válido",
      };
    });
  }
};
