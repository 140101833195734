import React, { useEffect, useState } from "react";
import "./index.css";

import useUser from "../../../../hooks/useUser";
import useEditUser from "../../hooks/useEditUser";

import { ClipLoader } from "react-spinners";

import { Button, Input } from "../../../../components";

function Account() {
  const { user, loadingUser } = useUser();
  const { editUser, editErrors, sendEditPasswordEmail } = useEditUser();

  const [newForm, setNewForm] = useState(user);

  const handleEditButtonClick = async () => {
    await editUser(newForm);
  };

  const handlePasswordButtonClick = async (e) => {
    sendEditPasswordEmail(user.email);
  };

  useEffect(() => {
    setNewForm(user);
  }, [user]);

  return (
    <div className="orion__account-section">
      <h2>Conta</h2>
      {loadingUser ? (
        <ClipLoader
          color="#D42A5B"
          size={50}
          aria-label="Loading Spinner"
          data-testid="loader"
          className="orion__loading"
        />
      ) : (
        <>
          <h3>Alterar dados pessoais</h3>
          <div className="orion__data">
            <div className="orion__data-container">
              <div className="orion__change-data">
                <div className="title-container">
                  <p>Telefone</p>
                </div>
                <div className="input-container">
                  <Input
                    label=""
                    name="phone_number"
                    customStyle="dark"
                    value={newForm.phone_number}
                    onChange={(e) =>
                      setNewForm({
                        ...newForm,
                        phone_number: e.target.value,
                      })
                    }
                    error={editErrors?.phone_number}
                  />
                </div>
              </div>
            </div>
            <div className="orion__data-edit-container">
              <div className="orion__data-edit">
                <Button
                  text="Alterar dados"
                  name="name_edit"
                  styleType="filled"
                  onClick={handleEditButtonClick}
                />
              </div>
            </div>
          </div>
          <h3>Alterar senha</h3>
          <div className="orion__data">
            <div className="orion__data-edit">
              <Button
                text="Enviar email de alteração de senha"
                name="name_edit"
                styleType="filled"
                onClick={handlePasswordButtonClick}
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default Account;
