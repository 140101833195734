import { useState, useEffect } from "react";

import orionAPI from "../config/orionAPI";

const useFavorites = () => {
  const [favorites, setFavorites] = useState([]);
  const [telegramLink, setTelegramLink] = useState("");
  const [loadingFavorites, setLoadingFavorites] = useState(true);
  const [loadingLink, setLoadingLink] = useState(true);
  const [loadingSetFavorites, setLoadingSetFavorites] = useState(false);
  const [response, setResponse] = useState(null);
  const [error, setError] = useState(null);

  const fetchTelegramLink = async () => {
    setLoadingLink(true);

    await orionAPI
      .post("api/notifications/chatbot/subscribe")
      .then((response) => {
        setTelegramLink(response.data.subscription_link);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoadingLink(false);
      });
  };

  const fetchFavorites = async () => {
    setLoadingFavorites(true);

    await orionAPI
      .get(`/api/notifications/crypto_pairs`)
      .then((response) => {
        setFavorites(response.data);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoadingFavorites(false);
      });
  };

  const setFavoritePairs = async (pairsToFavorite) => {
    setLoadingSetFavorites(true);
    await orionAPI
      .post(`/api/notifications/crypto_pairs`, {
        crypto_pairs: pairsToFavorite,
      })
      .then((response) => {
        setResponse(response.data);
        fetchFavorites();
      })
      .catch((error) => {
        setError(error.message);
      })
      .finally(() => {
        setLoadingSetFavorites(false);
      });
  };

  const changeNotificationStatus = async (status) => {
    await orionAPI
      .put(`/api/notifications/configuration`, {
        enable_chatbot_notifications: status,
      })
      .then((response) => {
        setResponse(response.data);
        window.location.reload();
      })
      .catch((error) => {
        setError(error.message);
      });
  };

  useEffect(() => {
    fetchFavorites();
    fetchTelegramLink();
  }, []);

  return {
    favorites,
    telegramLink,
    loadingFavorites,
    loadingSetFavorites,
    changeNotificationStatus,
    setFavoritePairs,
    loadingLink,
    response,
    error,
  };
};

export default useFavorites;
