import React, { useState, useEffect } from "react";
import "./index.css";
import { useNavigate } from "react-router-dom";

import logoSymbol from "../../../../assets/logo-light-symbol.png";
import logo from "../../../../assets/logo-light.png";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";

function Navbar() {
  const [stickyNavbar, setStickyNavbar] = useState(false);
  const breakpoint = 900;
  const [width, setWidth] = useState(window.innerWidth);
  const [openSidebar, setOpenSidebar] = useState(false);

  const navigate = useNavigate();

  const changeNavbarToSticky = () => {
    if (window.scrollY > 20) {
      setStickyNavbar(true);
    } else {
      setStickyNavbar(false);
    }
  };

  useEffect(() => {
    const handleResizeWindow = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResizeWindow);
    return () => window.removeEventListener("resize", handleResizeWindow);
  }, []);

  window.addEventListener("scroll", changeNavbarToSticky);

  return (
    <div
      className={
        stickyNavbar ? "orion__landing-navbar sticky" : "orion__landing-navbar"
      }
    >
      <div
        className="orion__landing-nav-logo-container"
        onClick={() => navigate("/")}
      >
        <img
          src={width > breakpoint ? logo : logoSymbol}
          className="orion__landing-nav-logo"
        />
      </div>

      <nav>
        {openSidebar ? (
          <ul className="orion__landing-navbar-sidebar">
            <XMarkIcon
              className="orion__lp-menu-icon"
              onClick={() => setOpenSidebar(false)}
            />
            <li>
              <a
                href={"/#orion__landing-presentation"}
                onClick={() => setOpenSidebar(false)}
              >
                Como funciona?
              </a>
            </li>
            <li>
              <a
                href="/#orion__features-section"
                onClick={() => setOpenSidebar(false)}
              >
                Benefícios
              </a>
            </li>
            <li>
              <a
                href="/#orion__plan-section"
                onClick={() => setOpenSidebar(false)}
              >
                Planos
              </a>
            </li>
            <li>
              <a
                href="/#orion__faq-section"
                onClick={() => setOpenSidebar(false)}
              >
                Dúvidas
              </a>
            </li>
            <li>
              <a href="/sobre-nos" onClick={() => setOpenSidebar(false)}>
                Quem somos?
              </a>
            </li>
            <div className="orion__navbar-buttons">
              <p onClick={() => navigate("/login")}>Entrar</p>
              <button
                className="orion__landing-navbar-button"
                onClick={() => navigate("/cadastro")}
              >
                Cadastre-se
              </button>
            </div>
          </ul>
        ) : (
          <></>
        )}
        {width > breakpoint ? (
          <div className="orion__lp-navbar-items">
            <ul>
              <li>
                <a href="/#orion__landing-presentation">Como funciona?</a>
              </li>
              <li>
                <a href="/#orion__features-section">Benefícios</a>
              </li>
              <li>
                <a href="/#orion__plan-section">Planos</a>
              </li>
              <li>
                <a href="/#orion__faq-section">Dúvidas</a>
              </li>
              <li>
                <a href="/sobre-nos">Quem somos?</a>
              </li>
            </ul>
            <div className="orion__navbar-buttons">
              <p onClick={() => navigate("/login")}>Entrar</p>
              <button
                className="orion__landing-navbar-button"
                onClick={() => navigate("/cadastro")}
              >
                Cadastre-se
              </button>
            </div>
          </div>
        ) : (
          <>
            {!openSidebar ? (
              <Bars3Icon
                className="orion__lp-menu-icon"
                onClick={() => setOpenSidebar(true)}
              />
            ) : (
              <></>
            )}
          </>
        )}
      </nav>
    </div>
  );
}

export default Navbar;
