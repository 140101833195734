import React, { useEffect, useState } from "react";
import "./subscriptionExpired.css";

import { useNavigate } from "react-router-dom";

import { FaceFrownIcon } from "@heroicons/react/24/outline";

import useUser from "../../hooks/useUser";

import { Navbar, Button, Footer } from "../../components";

function SubscriptionExpired() {
  const { user } = useUser();
  const navigate = useNavigate();

  useEffect(() => {
    if (user && user.subscription.active) {
      navigate("/dashboard");
    }
  }, [user.subscription]);

  return (
    <div>
      <Navbar user={user && user} />
      <div className="orion__page">
        <div className="orion__alert-floating-card">
          <div className="orion__alert-title">
            <FaceFrownIcon className="orion__alert-icon" />
            <h3>Poxa! As velas apagaram.</h3>
          </div>
          <p className="orion__alert-card-text">
            Adoraríamos que você permanecesse conosco. Para continuar usando
            nossos serviços, renove seu plano clicando no botão abaixo.
          </p>
          <Button
            styleType="alert"
            text="Prosseguir para o pagamento"
            onClick={() => navigate("/assinatura")}
          />
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default SubscriptionExpired;
