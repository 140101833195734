import React, { useEffect, useState } from "react";
import "./index.css";

import { Input } from "../../../../components";

function EarningsSimulator({ coinHistoryData }) {
  const [returnValue, setReturnValue] = useState("0.00000000");
  const [accumulatedTotal, setAccumulatedTotal] = useState("0.00000000");
  const [investedValue, setInvestedValue] = useState(100);
  const [returnPercentual, setReturnPercentual] = useState(0);

  const calculateResults = (investedValue) => {
    let initialValue = Number(investedValue).toFixed(8);
    let returnResult = initialValue;

    coinHistoryData.map((data) => {
      let percentage = Number(data.percentual.replace("%", "")) / 100;

      returnResult = Number(returnResult) + Number(returnResult * percentage);
    });

    setReturnValue((Number(returnResult) - Number(initialValue)).toFixed(2));
    setAccumulatedTotal(Number(returnResult).toFixed(2));
  };

  const handleValueChange = (e) => {
    setInvestedValue(e.target.value);
    calculateResults(e.target.value);
  };

  useEffect(() => {
    calculateResults(investedValue);
  }, [coinHistoryData]);

  useEffect(() => {
    if (investedValue) {
      setReturnPercentual(((returnValue / investedValue) * 100).toFixed(2));
    } else {
      setReturnPercentual(0);
    }
  }, [returnValue]);
  return (
    <div className="orion__simulator-container">
      <Input
        label="Valor Investido"
        value={investedValue}
        customStyle="dark"
        type="number"
        onChange={handleValueChange}
      />
      <div className="orion__result-field">
        <div className="orion__result-separator">
          <small>RETORNO *</small>
          <h1
            className={
              returnValue < 0
                ? "orion__value-negative"
                : "orion__value-positive"
            }
          >
            {returnValue.replace(".", ",")}
          </h1>
          <small>{`${returnPercentual}%`.replace(".", ",")}</small>
        </div>
        <hr></hr>
        <div className="orion__result-separator">
          <small>TOTAL ACUMULADO *</small>
          <h1
            className={
              accumulatedTotal < 0
                ? "orion__value-negative"
                : "orion__value-positive"
            }
          >
            {accumulatedTotal.replace(".", ",")}
          </h1>
        </div>
      </div>
    </div>
  );
}

export default EarningsSimulator;
