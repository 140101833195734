import React from "react";
import "./index.css";

import { QuestionMarkCircleIcon } from "@heroicons/react/24/outline";

function Tooltip({ text }) {
  return (
    <div className="orion__tooltip">
      <QuestionMarkCircleIcon className="orion__tooltip-icon" />
      <div className="orion__tooltip-bubble">{text}</div>
    </div>
  );
}

export default Tooltip;
