import React, { useEffect, useState } from "react";
import "./signUp.css";
import { LoginSignUpLayout } from "../../layouts";
import { Introduction, PersonalInfo, SignUpForm } from "./sections";
import { Button } from "../../components";
import { useNavigate } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import { ArrowLeftIcon } from "@heroicons/react/24/outline";

function SignUp() {
  const { signup, error, isAuthenticated, loading } = useAuth();

  const [page, setPage] = useState(1);
  const [errors, setErrors] = useState({});
  const [selectedCountry, setSelectedCountry] = useState("");
  const [pageErrors, setPageErrors] = useState({
    2: { state: false },
    3: { state: false },
  });

  const [brazilFormData, setBrazilFormData] = useState({
    address: {
      additional: null,
      cep: "",
      city: "",
      number: "",
      state: "",
      street: "",
      country: "",
    },
    birthday: "",
    cpf: "",
    email: "",
    first_name: "",
    last_name: "",
    password: "",
    passwordConfirmation: "",
    phone_number: "",
  });

  const [internationalFormData, setInternationalFormData] = useState({
    address: {
      additional: "",
      city: "",
      number: "",
      state: "",
      street: "",
      country: "",
    },
    birthday: "",
    email: "",
    first_name: "",
    last_name: "",
    password: "",
    passwordConfirmation: "",
    phone_number: "",
  });

  const navigate = useNavigate();

  const getEmptyFieldsErrors = (form) => {
    let emptyFieldsErrors = {};

    for (let item in form) {
      if (!form[item] && item !== "additional") {
        emptyFieldsErrors = {
          ...emptyFieldsErrors,
          [item]: "Por favor, preencha este campo",
        };
      }
    }

    if (page === 2) {
      if (form.password !== form.passwordConfirmation) {
        emptyFieldsErrors = {
          ...emptyFieldsErrors,
          passwordConfirmation: "As senhas não coincidem",
        };
      }
    }

    return emptyFieldsErrors;
  };

  const handleSubmit = async (e) => {
    if (!selectedCountry) {
      setPageErrors({ ...pageErrors, 1: { state: true } });
      setErrors({ country: "Selecione um país" });
    } else if (selectedCountry === "BR") {
      handleSubmitErrors(brazilFormData);
    } else {
      handleSubmitErrors(internationalFormData);
    }
  };

  const handleSubmitErrors = async (formData) => {
    if (page === 2) {
      const { address, ...firstFormData } = formData;

      let personalInfoErrors = getEmptyFieldsErrors(firstFormData);

      if (Object.keys(personalInfoErrors).length > 0) {
        setErrors((prev) => {
          return { ...prev, personalInfoErrors };
        });
      }

      let addressErrors = getEmptyFieldsErrors(address);

      if (Object.keys(addressErrors).length > 0) {
        setErrors((prev) => {
          return { ...prev, addressErrors };
        });
      } else {
        await signup(formData);

        for (let item in errors) {
          if (!errors[item]) {
            delete errors[item];
          }
        }

        if (!Object.keys(errors).length && isAuthenticated()) {
          navigate("/confirmacao-pendente", {
            replace: true,
            state: { email: formData.email },
          });
        }
      }
    } else {
      setPageErrors({ ...pageErrors, 1: { state: false } });
      setPage(page + 1);
    }
  };

  const conditionalComponent = () => {
    switch (page) {
      case 1:
        return (
          <Introduction
            errors={errors}
            setErrors={setErrors}
            setPage={setPage}
            pageErrors={pageErrors}
            selectedCountry={selectedCountry}
            setSelectedCountry={setSelectedCountry}
            handleSubmit={handleSubmit}
          />
        );
      case 2:
        return (
          <SignUpForm
            formData={
              selectedCountry === "BR" ? brazilFormData : internationalFormData
            }
            setFormData={
              selectedCountry === "BR"
                ? setBrazilFormData
                : setInternationalFormData
            }
            errors={errors}
            setErrors={setErrors}
            setPage={setPage}
            pageErrors={pageErrors}
            handleSubmit={handleSubmit}
            loading={loading}
          />
        );
    }
  };

  useEffect(() => {
    if (selectedCountry === "BR") {
      setBrazilFormData({
        ...brazilFormData,
        address: {
          ...brazilFormData.address,
          country: selectedCountry,
        },
      });
    } else {
      setInternationalFormData({
        ...internationalFormData,
        address: {
          ...internationalFormData.address,
          country: selectedCountry,
        },
      });
    }
  }, [selectedCountry]);

  useEffect(() => {
    if (selectedCountry === "BR") {
      setFormErrors(brazilFormData);
    } else {
      setFormErrors(internationalFormData);
    }
  }, [error?.errors]);

  const setFormErrors = (formData) => {
    let { address, ...firstFormData } = formData;

    if (error) {
      for (let item in error.errors) {
        setErrors((prev) => {
          return {
            ...prev,
            [item]: error.errors[item][0],
          };
        });
      }
    }
  };

  return (
    <div>
      <LoginSignUpLayout>{conditionalComponent()}</LoginSignUpLayout>
    </div>
  );
}

export default SignUp;
