const useIcons = () => {
  const getIconByCoinSymbol = (coinSymbol) => {
    try {
      const iconPath = require(`../assets/crypto-icons/${coinSymbol}.png`);
      return iconPath;
    } catch (err) {
      return null;
    }
  };

  const getFormattedIcon = (coin) => {
    return getIconByCoinSymbol(coin.pair.toLowerCase().substring(0, 3)) ? (
      <img
        className="coin-img"
        src={getIconByCoinSymbol(coin.pair.toLowerCase().substring(0, 3))}
      />
    ) : (
      <small>{coin.pair.substring(0, 1)}</small>
    );
  };

  return { getIconByCoinSymbol, getFormattedIcon };
};

export default useIcons;
