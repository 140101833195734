import React, { useState, useEffect, useRef } from "react";
import "./index.css";

import {
  ChevronRightIcon,
  ChevronLeftIcon,
  CheckIcon,
  MapPinIcon,
  EnvelopeIcon,
} from "@heroicons/react/24/outline";

import { StarIcon } from "@heroicons/react/24/solid";
import { useNavigate } from "react-router-dom";

import logo from "../../assets/logo-light.png";
import dashboardScreenshots from "../../assets/theros-screenshot.png";
import phoneImage from "../../assets/grafico-celular.png";
import orionBadge from "../../assets/orion-badge.png";
import { default as FaqSection } from "./sections/faq";

import { trades } from "./constants/trades";
import useIcons from "../../hooks/useIcons";

import { Footer } from "../../components";
import { Navbar } from "./components";

import babson from "../../assets/babson.jpg";

function LandingPage() {
  const { getIconByCoinSymbol } = useIcons();
  const carousel = useRef(null);
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResizeWindow = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResizeWindow);
    return () => window.removeEventListener("resize", handleResizeWindow);
  }, []);

  const navigate = useNavigate();

  const navigateToSignUp = () => {
    navigate("/cadastro");
  };

  const handleCarouselLeftClick = (e) => {
    e.preventDefault();
    carousel.current.scrollLeft -= carousel.current.offsetWidth;
  };

  const handleCarouselRightClick = (e) => {
    e.preventDefault();
    carousel.current.scrollLeft += carousel.current.offsetWidth;
  };

  const isDragging = (e) => {
    e.preventDefault();
    carousel.current.scrollLeft -= e.movementX;
  };

  return (
    <div className="orion__landing-page">
      <Navbar page={"landing"} />
      <div className="orion__landing-hero">
        <div className="title-container">
          <h1>Conheça as criptos mais lucrativas</h1>
          <h2>
            através dos nossos <b className="gradient">sinais estratégicos</b>
          </h2>
        </div>
        <p>
          Receba sinais de <b>compra</b> e <b>venda</b> de criptoativos em tempo
          real
        </p>
        <div className="orion__landing-hero-buttons">
          <a href="#orion__landing-presentation">
            <button>Conheça nossa plataforma</button>
          </a>
        </div>
        <small>
          Plano Mensal: R$ 127,00.{" "}
          <a className="underlined" href="#orion__plan-section">
            Ver outras opções
          </a>
        </small>
        <div className="hero">
          <img src={dashboardScreenshots} />
        </div>
      </div>
      <div class="orion__landing-presentation" id="orion__landing-presentation">
        <div className="orion__presentation-left-column">
          <small>SOBRE A PLATAFORMA</small>
          <h1>
            Auxiliamos você a tomar decisões com base em{" "}
            <b className="gradient">sinais estratégicos</b>
          </h1>
          <p>
            Nossos estratégias são desenvolvidas por uma equipe de especialistas
            com anos de experiência no mercado de criptoativos, utilizando uma
            combinação de técnicas de análise técnica e fundamental para
            identificar as melhores oportunidades de investimento.
          </p>
          <p></p>
          <div className="orion__presentation-button">
            <a style={{ textDecoration: "none" }} href="#orion__plan-section">
              <button>Conheça nossos planos</button>
            </a>
            <a href="#orion__faq-section">Dúvidas? Confira nosso FAQ</a>
          </div>
        </div>
        <div className="orion__presentation-right-column">
          <img
            className="orion__phone-image"
            src={phoneImage}
            alt="grafico-celular"
          ></img>
        </div>
      </div>

      <div className="orion__gradient-section">
        <div className="orion__features-section" id="orion__features-section">
          <div className="orion__features-header">
            <div className="orion__features-header-text">
              <small>BENEFÍCIOS</small>
              <p>
                Nossa plataforma é ideal para quem não tem tempo para acompanhar
                constantemente o mercado cripto e não quer perder a oportunidade
                de investir!
              </p>
            </div>
            <div className="orion__carousel-buttons-wrapper">
              <div
                className="orion__carousel-button"
                onClick={handleCarouselLeftClick}
              >
                <ChevronLeftIcon />
              </div>
              <div
                className="orion__carousel-button"
                onClick={handleCarouselRightClick}
              >
                <ChevronRightIcon />
              </div>
            </div>
          </div>
          <div
            className="orion__carousel-wrapper"
            ref={carousel}
            onDrag={isDragging}
          >
            <div className="orion__carousel-card-container">
              <div className="orion__carousel-card">
                <small>RECEBA VIA TELEGRAM</small>
                <p>
                  Receba sinais diretamente em seu Telegram em um canal
                  exclusivo
                </p>
              </div>
            </div>
            <div className="orion__carousel-card-container">
              <div className="orion__carousel-card">
                <small>MAIORES LUCROS</small>
                <p>
                  Aumente suas chances de sucesso no mercado de criptoativos
                </p>
              </div>
            </div>
            <div className="orion__carousel-card-container">
              <div className="orion__carousel-card">
                <small>ECONOMIA DE TEMPO</small>
                <p>Ganhe tempo e evite erros de análise</p>
              </div>
            </div>

            <div className="orion__carousel-card-container">
              <div className="orion__carousel-card">
                <small>MAIS DE 240 CRIPTOS</small>
                <p>
                  Aprenda sobre o mercado de criptoativos com os melhores
                  especialistas
                </p>
              </div>
            </div>
            <div className="orion__carousel-card-container">
              <div className="orion__carousel-card">
                <small>REALIZE OPERAÇOES</small>
                <p>Faça operações na corretora de sua preferência</p>
              </div>
            </div>
            <div className="orion__carousel-card-container">
              <div className="orion__carousel-card">
                <small>RECEBA VIA WHATSAPP</small>
                <p>Em breve!</p>
              </div>
            </div>
          </div>
        </div>
        <div className="orion__signal-section">
          <div className="orion__signal-list-header">
            <img src={orionBadge} className="orion__badge"></img>
            <div className="orion__signal-list-header-text">
              <h2>Acompanhe os sinais dos principais criptoativos</h2>
              <p>
                Nossa plataforma fornece todo o histórico de sinais de compra e
                venda para cada ativo em tempo real. Aproveite as melhores
                oportunidades do mercado sem perder tempo com análises complexas
                e inconclusivas!{" "}
              </p>
            </div>
            <div className="orion__signal-tags">
              {trades.map((trade) => (
                <div className="orion__signal-tag">
                  <div className="orion__signal-tag-name">
                    <img
                      src={getIconByCoinSymbol(trade.simbolo)}
                      alt={trade.simbolo}
                      className="orion__signal-icon"
                    />
                    <small>{trade.nome}</small>
                  </div>

                  <small className="orion__signal-tag-profit">
                    {trade.percentual}
                  </small>
                </div>
              ))}
            </div>
          </div>
          <div className="orion__signal-list-right-column">
            <div className="orion__signal-list">
              {trades.map((trade) => (
                <div className="orion__signal" key={trade.simbolo}>
                  <div className="orion__signal-info">
                    <img
                      src={getIconByCoinSymbol(trade.simbolo)}
                      alt={trade.simbolo}
                      className="orion__signal-icon"
                    />
                    <div className="orion__signal-name">
                      <p>{trade.nome}</p>
                      <small>{trade.simbolo.toUpperCase() + "/USDT"}</small>
                    </div>
                  </div>
                  <div className="orion__signal-data-wrapper">
                    <div className="orion__signal-data">
                      <small>DATA DA COMPRA</small>
                      <p>{trade.dataCompra}</p>
                    </div>
                    <div className="orion__signal-data">
                      <small>VALOR DA COMPRA</small>
                      <p>{trade.valorCompra}</p>
                    </div>
                    <div className="orion__signal-data">
                      <small>DATA DA VENDA</small>
                      <p>{trade.dataVenda}</p>
                    </div>
                    <div className="orion__signal-data">
                      <small>VALOR DA VENDA</small>
                      <p>{trade.valorVenda}</p>
                    </div>
                    <div className="orion__signal-data">
                      <small>DURAÇÃO</small>
                      <p>{trade.duracao}</p>
                    </div>
                    <div className="orion__signal-data">
                      <small>LUCRO</small>
                      <p
                        className={
                          trade.percentual.includes("+")
                            ? "positive"
                            : "negative"
                        }
                      >
                        {trade.percentual}
                      </p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="orion__plan-section" id="orion__plan-section">
          <div className="orion__plan-section-title">
            <small>PLANO</small>
            <h1>Pronto para receber sinais?</h1>
            <p>
              Oferecemos os melhores possibilidades de sinais de venda e compra,
              confira nossos planos!
            </p>
            <p>
              Caso não fique satisfeito, devolvemos seu dinheiro{" "}
              <b>em até 7 dias.</b>
            </p>
            <small>TODOS OS PLANOS OFERECEM:</small>
            <div className="orion__plan-section-benefit">
              <CheckIcon className="benefit-icon" />
              <p>Simulador de ganhos</p>
            </div>
            <div className="orion__plan-section-benefit">
              <CheckIcon className="benefit-icon" />
              <p>Sinais via Telegram</p>
            </div>
            <div className="orion__plan-section-benefit">
              <CheckIcon className="benefit-icon" />
              <p>Compra 100% segura</p>
            </div>
            <div className="orion__plan-section-benefit">
              <CheckIcon className="benefit-icon" />
              <p>Pagamento via Pix</p>
            </div>
          </div>
          <div className="orion__plan-cards-wrapper">
            <div className="orion__plan-card best">
              <div className="orion__plan-card-banner">ECONOMIZE 40%</div>
              <div className="orion__plan-card-header">
                <div className="orion__plan-card-previous-price">
                  <small>DE </small>
                  <small className="dashed">R$1.524</small>
                  <small> POR</small>
                </div>
                <h1>R$914</h1>
                <p>Plano Anual</p>
              </div>
              <div className="orion__plan-card-button-wrapper">
                <button onClick={navigateToSignUp}>Assinar agora</button>
              </div>
            </div>
            <div className="orion__plan-card">
              <div className="orion__plan-card-header">
                <div className="orion__plan-card-previous-price">
                  <small>DE </small>
                  <small className="dashed">R$381</small>
                  <small> POR</small>
                </div>
                <h1>R$285</h1>
                <p>Plano Trimestral</p>
              </div>
              <div className="orion__plan-card-button-wrapper">
                <button onClick={navigateToSignUp}>Assinar agora</button>
              </div>
            </div>
            <div className="orion__plan-card">
              <div className="orion__plan-card-header">
                <div className="orion__plan-card-previous-price">
                  <small>PAGAMENTO ÚNICO</small>
                </div>
                <h1>R$127</h1>
                <p>Plano Mensal</p>
              </div>
              <div className="orion__plan-card-button-wrapper">
                <button onClick={navigateToSignUp}>Assinar agora</button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="orion__faq-section" id="orion__faq-section">
        <div className="orion__faq-header">
          <small>DÚVIDAS</small>
          <h2>Confira as principais dúvidas a respeito dos sinais</h2>
          <p>
            Não conseguiu sanar todas as suas dúvidas? Estamos aqui pra ajudar!
            Envie suas dúvidas e sugestões e nos ajude a construir a melhor
            plataforma para você!
          </p>
          <a
            style={{ textDecoration: "underline" }}
            href="#orion__contact-section"
          >
            Dúvidas? Converse conosco
          </a>
        </div>
        <div className="orion__faq-content">
          <FaqSection />
        </div>
      </div>
      <div className="orion__about-section" id="orion__about-section">
        <img src={babson} className="orion__about-image"></img>
        <div className="orion__about-content">
          <img src={logo}></img>
          <div className="orion__about-content-text">
            <p>
              Imersos no empreendedorismo, na Babson College em Boston/MA - EUA,
              embarcamos em uma jornada para criar a Orion Sinais unidos por uma
              paixão compartilhada por tecnologia, blockchain e criptomoedas.
            </p>
            <p>
              Nossos especialistas desenvolveram um algoritmo automatizado que
              enviam sinais para a plataforma e para o Telegram.{" "}
            </p>
          </div>
          <button onClick={() => navigate("/sobre-nos")}>Saiba mais</button>
        </div>
      </div>
      <div className="orion__contact-section" id="orion__contact-section">
        <div className="orion__contact-section-header">
          <small>ENTRE EM CONTATO</small>
          <h1>Nossos especialistas estão prontos para atender você!</h1>
        </div>
        <div className="orion__contact-section-content">
          <div className="orion__lp-contact-info">
            <p>
              Criamos esse canal de comunicação para que você possa esclarecer
              todas as suas dúvidas:
            </p>
            <div className="orion__contact-info-content">
              <EnvelopeIcon className="orion__contact-icon" />
              <p>
                <b>contato@orionsinais.com.br</b>
              </p>
            </div>
            <hr />
            <div className="orion__contact-info-content">
              <MapPinIcon className="orion__contact-icon" />
              <div className="orion__contact-info-address">
                <p>
                  <b>Av. Paulista, 1636 - Conjunto 4</b>
                </p>
                <p>Pav. 1504 - São Paulo/SP</p>
              </div>
            </div>
          </div>
          <div className="orion__contact-form">
            <p>
              Preencha o formulário para mais informações, dúvidas ou sugestões
            </p>
            <iframe
              aria-label="Entre em contato"
              frameborder="0"
              width="100%"
              height="500"
              src="https://forms.zohopublic.com/orionsinais/form/Entreemcontato1/formperma/ycV-qozeIk6iWWK0CAhMJJbdfG3JyVviuyNcQ_KzySE"
            ></iframe>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default LandingPage;
