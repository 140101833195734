import React, { useState } from "react";
import "./userConfig.css";

import { Footer, Navbar } from "../../components";
import { Account, Address, TelegramNotification } from "./sections";
import { XMarkIcon, Bars3Icon } from "@heroicons/react/24/outline";

function UserConfig() {
  const [currentSection, setCurrentSection] = useState("account");
  const [showSidebar, setShowSidebar] = useState(false);

  const conditionalComponent = () => {
    switch (currentSection) {
      case "account":
        return <Account />;
      case "address":
        return <Address />;
      case "telegram-notification":
        return <TelegramNotification />;
    }
  };

  return (
    <div>
      <Navbar />
      <div className="orion__user-config">
        <div className="orion__user-config-header">
          <Bars3Icon
            className="orion__user-config-header-icon"
            onClick={() => setShowSidebar(true)}
          />
          <h1>Configurações</h1>
        </div>
        <div className="orion__config-container">
          <div className="orion__config-sidebar">
            <div
              className={
                currentSection === "account"
                  ? "orion__sidebar-item selected"
                  : "orion__sidebar-item"
              }
              onClick={() => setCurrentSection("account")}
            >
              Conta
            </div>
            <div
              className={
                currentSection === "address"
                  ? "orion__sidebar-item selected"
                  : "orion__sidebar-item"
              }
              onClick={() => setCurrentSection("address")}
            >
              Endereço
            </div>
            <div
              className={
                currentSection === "telegram-notification"
                  ? "orion__sidebar-item selected"
                  : "orion__sidebar-item"
              }
              onClick={() => setCurrentSection("telegram-notification")}
            >
              Notificações do Telegram
            </div>
          </div>
          {showSidebar && (
            <div className="orion__config-sidebar-mobile">
              <div className="orion__cg-sidebar-icon-container">
                <XMarkIcon
                  className="orion__config-sidebar-icon"
                  onClick={() => setShowSidebar(false)}
                />
              </div>
              <div
                className={
                  currentSection === "account"
                    ? "orion__sidebar-item selected"
                    : "orion__sidebar-item"
                }
                onClick={() => {
                  setShowSidebar(false);
                  setCurrentSection("account");
                }}
              >
                Conta
              </div>
              <div
                className={
                  currentSection === "address"
                    ? "orion__sidebar-item selected"
                    : "orion__sidebar-item"
                }
                onClick={() => {
                  setShowSidebar(false);
                  setCurrentSection("address");
                }}
              >
                Endereço
              </div>
              <div
                className={
                  currentSection === "telegram-notification"
                    ? "orion__sidebar-item selected"
                    : "orion__sidebar-item"
                }
                onClick={() => {
                  setShowSidebar(false);
                  setCurrentSection("telegram-notification");
                }}
              >
                Notificações do Telegram
              </div>
            </div>
          )}
          <div className="orion__config-section">{conditionalComponent()}</div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default UserConfig;
