import { useState, useEffect } from 'react'

import orionAPI from '../../../config/orionAPI'

const useEditUser = () => {
    const [isUserEdited, setIsUserEdited] = useState(false)
    const [loadingEditUser, setLoadingEditUser] = useState(true)
    const [editErrors, setEditErrors] = useState()

    const editUser = async (user) => {
        setLoadingEditUser(true)
        setIsUserEdited(false)

        await orionAPI.put(`/api/users/me`, user)
            .then((response) => {
                setIsUserEdited(true)
                window.location.reload()
            })
            .catch((error) => {
                setEditErrors(error.response.data.errors)
            })
            .finally(() => {
                setLoadingEditUser(false)
            })
    }

    return { editUser, isUserEdited, loadingEditUser, editErrors }
}

export default useEditUser;