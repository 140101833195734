export const formatDate = (date) => {
  const dateArray = date.split("/");
  const formattedDate = [];

  formattedDate.push(dateArray[2], "/", dateArray[1], "/", dateArray[0]);

  return formattedDate.join().replaceAll(",", "");
};

export const getSubscriptionEndTime = (user) => {
  let currentDay = new Date();
  let subscriptionEndDate = new Date(user.subscription.end_date);

  let subscriptionEndTime = (
    (subscriptionEndDate - currentDay) /
    (1000 * 60 * 60 * 24)
  ).toFixed(0);

  return subscriptionEndTime;
};
