import React, { useEffect } from 'react'
import './emailConfirmation.css'

import { LoginSignUpLayout } from '../../layouts';
import { Button } from '../../components'

import confirmationCompleteImage from '../../assets/confirmation-complete.svg'
import { useLocation, useNavigate } from 'react-router-dom';
import orionAPI from '../../config/orionAPI';

function EmailConfirmation() {
    const navigate = useNavigate();
    const search = useLocation().search;
    const userToken = new URLSearchParams(search).get('t');

    const validateUser = () => {
        orionAPI.post('/api/confirm_user/', {
            token: userToken
        })
    }

    useEffect(() => {
        if (userToken) {
            validateUser()
        } else {
            navigate('/login')
        }
    }, [])

    return (
        <LoginSignUpLayout>
            <div className='orion__email-confirmation'>
                <img src={confirmationCompleteImage}></img>
                <h1>Parabéns! Sua conta foi confirmada.</h1>
                <p>Clique no botão abaixo para voltar à página de login.</p>
                <Button
                    styleType='filled'
                    text='Voltar ao início'
                    onClick={() => navigate('/login')}
                />
            </div>
        </LoginSignUpLayout>
    )
}

export default EmailConfirmation