import React, { useEffect, useState } from "react";
import "./index.css";

import useUser from "../../../../hooks/useUser";
import useEditUser from "../../hooks/useEditUser";

import { ClipLoader } from "react-spinners";
import { Button, Input } from "../../../../components";
import { CityAndStateDropdown } from "../../../signUp/components";
import useStatesAndCities from "../../../../hooks/useStatesAndCities";

function Address() {
  const { user, loadingUser } = useUser();
  const { editUser, editErrors, setEditErrors } = useEditUser();

  const { states, cities, fetchCitiesByStateId } = useStatesAndCities();
  const [newForm, setNewForm] = useState(user);

  const handleEditButtonClick = async () => {
    await editUser(newForm);
  };

  useEffect(() => {
    setNewForm(user);
  }, [user]);

  useEffect(() => {
    if (states && newForm.address.country === "BR") {
      let state = states.find((item) => {
        return item.sigla === newForm.address.state;
      });
      fetchCitiesByStateId(state.id);
    }
  }, [newForm?.address.state]);

  return (
    <div className="orion__account-section">
      <h2>Endereço</h2>
      {loadingUser ? (
        <ClipLoader
          color="#D42A5B"
          size={50}
          aria-label="Loading Spinner"
          data-testid="loader"
          className="orion__loading"
        />
      ) : (
        <div className="orion__data">
          <div className="orion__data-container">
            <div className="orion__change-data">
              <div className="title-container">
                <p>CEP</p>
              </div>
              <div className="input-container">
                <Input
                  label=""
                  name="cep"
                  customStyle="dark"
                  value={newForm.address.cep}
                  onChange={(e) =>
                    setNewForm({
                      ...newForm,
                      address: {
                        ...newForm.address,
                        cep: e.target.value,
                      },
                    })
                  }
                  error={editErrors?.address.cep}
                />
              </div>
            </div>
          </div>
          <div className="orion__data-container">
            <div className="orion__change-data">
              <div className="title-container">
                <p>Rua</p>
              </div>
              <div className="input-container">
                <Input
                  label=""
                  name="street"
                  customStyle="dark"
                  value={newForm.address.street}
                  onChange={(e) =>
                    setNewForm({
                      ...newForm,
                      address: {
                        ...newForm.address,
                        street: e.target.value,
                      },
                    })
                  }
                  error={editErrors?.address.street}
                />
              </div>
            </div>
          </div>
          <div className="orion__data-container">
            <div className="orion__change-data">
              <div className="title-container">
                <p>Número</p>
              </div>
              <div className="input-container">
                <Input
                  label=""
                  name="number"
                  customStyle="dark"
                  value={newForm.address.number}
                  onChange={(e) =>
                    setNewForm({
                      ...newForm,
                      address: {
                        ...newForm.address,
                        number: e.target.value,
                      },
                    })
                  }
                  error={editErrors?.address.number}
                />
              </div>
            </div>
          </div>
          <div className="orion__data-container">
            <div className="orion__change-data">
              <div className="title-container">
                <p>Complemento</p>
              </div>
              <div className="input-container">
                <Input
                  label=""
                  name="additional"
                  customStyle="dark"
                  value={newForm.address.additional}
                  onChange={(e) =>
                    setNewForm({
                      ...newForm,
                      address: {
                        ...newForm.address,
                        additional: e.target.value,
                      },
                    })
                  }
                  error={editErrors?.address.additional}
                />
              </div>
            </div>
          </div>
          <div className="orion__data-container">
            <div className="orion__change-data">
              <div className="title-container">
                <p>Cidade e Estado</p>
              </div>
              {user.address.country === "BR" ? (
                <div className="orion__double-input">
                  <div className="input-container">
                    {newForm.address.state && states && (
                      <CityAndStateDropdown
                        label="estado"
                        options={states}
                        setData={setNewForm}
                        data={newForm}
                        currentSelected={newForm.address.state}
                        style="dark"
                      />
                    )}
                  </div>
                  <div className="input-container">
                    {newForm.address.city && cities && (
                      <CityAndStateDropdown
                        label="cidade"
                        options={cities}
                        setData={setNewForm}
                        data={newForm}
                        currentSelected={newForm.address.city}
                        style="dark"
                      />
                    )}
                  </div>
                </div>
              ) : (
                <div className="orion__double-input">
                  <div className="input-container">
                    <Input
                      label=""
                      name="state"
                      customStyle="dark"
                      value={newForm.address.state}
                      onChange={(e) =>
                        setNewForm({
                          ...newForm,
                          address: {
                            ...newForm.address,
                            state: e.target.value,
                          },
                        })
                      }
                      error={editErrors?.address.state}
                      maxLength={2}
                    />
                  </div>
                  <div className="input-container">
                    <Input
                      label=""
                      name="city"
                      customStyle="dark"
                      value={newForm.address.city}
                      onChange={(e) =>
                        setNewForm({
                          ...newForm,
                          address: {
                            ...newForm.address,
                            city: e.target.value,
                          },
                        })
                      }
                      error={editErrors?.address.city}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="orion__data-edit-container">
            <div className="orion__data-edit">
              <Button
                text="Alterar dados"
                name="name_edit"
                styleType="filled"
                onClick={handleEditButtonClick}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Address;
