import { createContext, useState } from "react";
import orionAPI from "../config/orionAPI";
import { useNavigate } from "react-router-dom";

export const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);

  const signin = async (credentials) => {
    setError(null);
    await orionAPI
      .post("/api/sign_in", credentials)
      .then((response) => {
        localStorage.setItem("userToken", response.data.token);
      })
      .catch((error) => {
        setError(error.response.data);
      });
  };

  const signup = async (credentials) => {
    setError(null);
    setLoading(true);
    await orionAPI
      .post("/api/sign_up/", credentials)
      .then((response) => {
        localStorage.setItem("userToken", response.data.token);
        setError(false);
        setLoading(false);
      })
      .catch((error) => {
        setError(error.response.data);
        setLoading(false);
      });
  };

  const logout = () => {
    localStorage.removeItem("userToken");
  };

  const isAuthenticated = () => {
    return localStorage.getItem("userToken") !== null;
  };

  return (
    <AuthContext.Provider
      value={{ signin, signup, logout, isAuthenticated, error, loading }}
    >
      {children}
    </AuthContext.Provider>
  );
};
