import React from 'react'
import './searchBar.css'

import { MagnifyingGlassIcon } from '@heroicons/react/24/outline'

function SearchBar({ placeholder, onChange }) {
    return (
        <div className='orion__search-container'>
            <input
                className='orion__search-input'
                name='search'
                type='search'
                placeholder={placeholder}
                onChange={onChange}
            />
            <MagnifyingGlassIcon className='orion__search-icon' />
        </div>
    )
}

export default SearchBar