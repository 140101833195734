import React, { useEffect, useState } from "react";
import "./index.css";

import { Line } from "react-chartjs-2";

import {
  Chart as ChartJS,
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
  Tooltip,
} from "chart.js";

ChartJS.register(
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
  Tooltip
);

function CoinChart({ coinHistoryData }) {
  const [chartLabels, setChartLabels] = useState([]);
  const [chartData, setChartData] = useState([]);
  const legends = [
    { title: "Compra", color: "#4680D6" },
    { title: "Venda", color: "#F53E64" },
    { title: "Parcial", color: "#F5A929" },
  ];

  const setChartLabelsFromHistoryData = () => {
    let labels = [];
    coinHistoryData.map((historyData) => {
      labels.push(
        historyData.dataCompra,
        historyData.dataVenda ? historyData.dataVenda : ""
      );
    });
    setChartLabels(labels);
  };

  const setChartDataFromHistoryData = () => {
    let data = [];
    coinHistoryData.map((historyData) => {
      data.push(historyData.valorCompra, historyData.valorVenda);
    });
    setChartData(data);
  };

  const data = {
    labels: chartLabels,
    datasets: [
      {
        label: "Valor",
        data: chartData,
        backgroundColor: "#fff",
        borderColor: "#8F8D9B",
        pointBackgroundColor: (context) => {
          if (
            coinHistoryData.find(
              ({ valorVenda, dataVenda }) =>
                valorVenda === context.raw && !dataVenda
            )
          ) {
            return "#F5A929";
          } else if (
            coinHistoryData.find(
              ({ valorCompra }) => valorCompra === context.raw
            )
          ) {
            return "#4680D6";
          } else if (
            coinHistoryData.find(({ valorVenda }) => valorVenda === context.raw)
          ) {
            return "#F53E64";
          }
        },
        pointBorderColor: (context) => {
          if (
            coinHistoryData.find(
              ({ valorVenda, dataVenda }) =>
                valorVenda === context.raw && !dataVenda
            )
          ) {
            return "#F5A929";
          } else if (
            coinHistoryData.find(
              ({ valorCompra }) => valorCompra === context.raw
            )
          ) {
            return "#4680D6";
          } else if (
            coinHistoryData.find(({ valorVenda }) => valorVenda === context.raw)
          ) {
            return "#F53E64";
          }
        },
        pointStyle: "circle",
        pointRadius: 5,
        pointHoverRadius: 8,
      },
    ],
  };

  const config = {
    type: "line",
    data: data,
    options: {
      responsive: true,
      plugins: {
        legend: {
          display: false,
        },
        title: {
          display: true,
          text: (ctx) =>
            "Point Style: " + ctx.chart.data.datasets[0].pointStyle,
        },
      },
    },
  };
  useEffect(() => {
    setChartLabelsFromHistoryData();
    setChartDataFromHistoryData();
  }, [coinHistoryData]);

  const options = {
    maintainAspectRatio: false,
    plugins: {
      tooltip: {
        callbacks: {
          title: (context) => {
            if (
              coinHistoryData.find(
                ({ valorVenda, dataVenda }) =>
                  valorVenda === context[0].raw && !dataVenda
              )
            ) {
              return "Cotação Atual (Parcial)" + context[0].label;
            } else if (
              coinHistoryData.find(
                ({ valorCompra }) => valorCompra === context[0].raw
              )
            ) {
              return "Compra em " + context[0].label;
            } else if (
              coinHistoryData.find(
                ({ valorVenda }) => valorVenda === context[0].raw
              )
            ) {
              return "Venda em " + context[0].label;
            }
          },
          label: (context) => {
            return `Valor: ${context.raw}`;
          },
        },
      },
    },
    scales: {
      y: {
        ticks: {
          callback: function (value, index, ticks) {
            return `${value}`;
          },
        },
      },
    },
  };

  return (
    <div className="orion__chart-container">
      <Line config={config} data={data} options={options} id="orion__chart" />
      <div className="orion__chart-legend-container">
        {legends.map((item) => (
          <div className="orion__chart-legend">
            <div
              className="orion__chart-legend-color"
              style={{ backgroundColor: item.color }}
            />
            <small>{item.title}</small>
          </div>
        ))}
      </div>
    </div>
  );
}

export default CoinChart;
