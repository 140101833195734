import React, { useEffect, useState } from "react";
import "./homepage.css";

import {
  PairSelector,
  SignalCard,
  ChartInfo,
  CoinChart,
  HistoryTable,
  EarningsSimulator,
} from "./components";

import { Navbar, DatePicker, Footer, Tooltip } from "../../components";

import { filterHistoryData } from "./helpers/filterHelpers";
import { formatDate, getSubscriptionEndTime } from "./helpers/dateHelpers";

import useCoinHistoryData from "./hooks/useCoinHistoryData";
import useCoinPairList from "./hooks/useCoinPairList";
import useOpenStrategies from "../../hooks/useOpenStrategies";

import ClipLoader from "react-spinners/ClipLoader";

import { ArrowRightIcon } from "@heroicons/react/24/outline";

import { useNavigate } from "react-router-dom";

import useUser from "../../hooks/useUser";

function Homepage() {
  const [coinPairSymbol, setCoinPairSymbol] = useState("BTCUSDT");
  const [initialDate, setInitialDate] = useState("2020/01/01");
  const [endDate, setEndDate] = useState(
    new Date().toJSON().slice(0, 10).replace(/-/g, "/")
  );
  const [filteredCoinHistoryData, setFilteredCoinHistoryData] = useState([]);
  const [subscriptionEndTime, setSubscriptionEndTime] = useState();

  const { coinHistoryData, loadingCoinHistoryData } =
    useCoinHistoryData(coinPairSymbol);
  const { coinPairList, loadingCoinPairList } = useCoinPairList();
  const { openStrategies, loadingOpenStrategies } = useOpenStrategies();

  const navigate = useNavigate();

  useEffect(() => {
    if (coinHistoryData)
      setFilteredCoinHistoryData(
        filterHistoryData(coinHistoryData, initialDate, endDate)
      );
  }, [coinHistoryData, initialDate, endDate]);

  return (
    <div className="orion__homepage">
      <Navbar />
      {subscriptionEndTime && subscriptionEndTime <= 7 ? (
        <div className="orion__subscription-alert">
          <div className="orion__subscription-alert-text">
            <p>{`Sua assinatura irá acabar em ${
              subscriptionEndTime === 1
                ? subscriptionEndTime + " dia"
                : subscriptionEndTime + " dias"
            }. Não fique sem nossos serviços! :(`}</p>
          </div>
        </div>
      ) : (
        ""
      )}
      <div className="orion__content">
        <div className="orion__left-column">
          <div className="orion__section-container">
            <div className="orion__title-container">
              <h2 className="orion__section-title">FILTRAR SINAIS</h2>
              <small>
                Selecione a moeda que deseja visualizar e indique o período de
                análise
              </small>
            </div>

            {loadingCoinPairList ? (
              <ClipLoader
                color="#D42A5B"
                size={50}
                aria-label="Loading Spinner"
                data-testid="loader"
                className="orion__loading"
              />
            ) : (
              <PairSelector
                label="Selecione o par"
                coinPairSymbol={coinPairSymbol}
                pairList={coinPairList}
                setCoinPairSymbol={setCoinPairSymbol}
              />
            )}
            <div className="orion__date-container">
              <DatePicker
                label="Data Inicial"
                date={initialDate}
                setDate={setInitialDate}
              />
              <DatePicker
                label="Data Final"
                date={endDate}
                setDate={setEndDate}
              />
            </div>
          </div>
          <div className="orion__section-container">
            <div className="orion__title-container">
              <div className="orion__tooltip-container">
                <h2 className="orion__section-title">SIMULAR GANHOS</h2>
                <Tooltip
                  text={
                    "O simulador de ganhos calcula o retorno aproximado de um investimento inicial, baseado nas operações executadas conforme os sinais da estratégia, utilizando o par de moedas selecionado e as datas especificadas. A análise considera apenas os sinais de compra e venda dentro do período escolhido."
                  }
                />
              </div>
              <small>
                Simule os ganhos durante todo o período de análise da moeda
                escolhida
              </small>
            </div>

            {loadingCoinHistoryData ? (
              <ClipLoader
                color="#D42A5B"
                size={50}
                aria-label="Loading Spinner"
                data-testid="loader"
                className="orion__loading"
              />
            ) : (
              <EarningsSimulator coinHistoryData={filteredCoinHistoryData} />
            )}
            <small className="orion__disclaimer">
              * Os cálculos foram realizados sem inclusão das taxas de
              negociação comumente cobradas pelas corretoras nas transações de
              compra e venda, e sem contemplar os impostos conforme a legislação
              vigente no país de operação. Recomenda-se sempre considerar esses
              elementos ao realizar transações por meio da corretora escolhida.
            </small>
          </div>
          <div className="orion__section-container">
            <div className="orion__title-container">
              <div className="orion__tooltip-container">
                <h2 className="orion__section-title">SINAIS EM ABERTO</h2>
                <Tooltip
                  text={
                    "As operações indicam apenas sinais de compra e aguardam sinais de venda. Os lucros listados são parciais e baseados na cotação atual dos ativos, úteis para acompanhamento ou para sair da posição antes de um sinal de venda."
                  }
                />
              </div>
              <small>Verifique os sinais que estão em aberto atualmente</small>
            </div>

            <div className="orion__open-strategies-container">
              {loadingOpenStrategies ? (
                <ClipLoader
                  color="#D42A5B"
                  size={50}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                  className="orion__loading"
                />
              ) : (
                openStrategies
                  .slice(0, 5)
                  .map((strategy, index) => (
                    <SignalCard
                      key={strategy.pair}
                      pairSymbol={strategy.pair}
                      buyDate={strategy.last_candle_date}
                      buyValue={strategy.last_close}
                      profitPercentual={
                        strategy.profit_percentage.toFixed(2) + "%"
                      }
                      sellValue={strategy.current_price}
                    />
                  ))
              )}
            </div>
            <div
              className="orion__open-strategies-link"
              onClick={() => navigate("/sinais-abertos")}
            >
              <small>Visualizar todas os sinais em aberto</small>
              <ArrowRightIcon className="orion__right-arrow"></ArrowRightIcon>
            </div>
          </div>
        </div>
        <div className="orion__right-column">
          <ChartInfo
            pairName={coinPairSymbol}
            initialDate={initialDate}
            endDate={endDate}
            formatDate={formatDate}
          />
          {loadingCoinHistoryData ? (
            <div className="orion__section-container">
              <ClipLoader
                color="#D42A5B"
                size={50}
                aria-label="Loading Spinner"
                data-testid="loader"
                className="orion__loading"
              />
            </div>
          ) : (
            <CoinChart coinHistoryData={filteredCoinHistoryData} />
          )}
          {loadingCoinHistoryData ? (
            <div className="orion__section-container">
              <ClipLoader
                color="#D42A5B"
                size={50}
                aria-label="Loading Spinner"
                data-testid="loader"
                className="orion__loading"
              />
            </div>
          ) : (
            <HistoryTable coinHistoryData={filteredCoinHistoryData} />
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Homepage;
