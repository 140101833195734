export const faqQuestions = [
  {
    section: "signup",
    questions: [
      {
        question: "Todos os assinantes e usuários recebem o mesmo conteúdo?",
        answer:
          "Sim, todos os assinantes têm acesso ao mesmo conteúdo na plataforma Orion Sinais. Isso inclui o simulador de ganhos, gráficos, mensagens diárias no Telegram e quaisquer outros serviços ou funcionalidades disponibilizados. A diferenciação entre os planos se dá apenas pela vigência do acesso e pelos descontos aplicados durante o período selecionado.",
      },
      {
        question: "Como posso usufruir da plataforma?",
        answer:
          "É muito simples! Após aderir ao plano de sua preferência, você começará a receber notificações de compra e venda de criptoativos via Telegram, no telefone cadastrado. Se desejar visualizar o histórico de sinais de compra e venda de um ativo específico, basta selecionar o ativo na página principal e escolher o período desejado para consulta. Além disso, você pode utilizar o simulador de ganhos para avaliar a rentabilidade de um ativo em um período selecionado.",
      },
      {
        question: "Como faço para acessar a plataforma?",
        answer:
          'Para acessar a plataforma Orion Sinais, basta acessar o site www.orionsinais.com.br e fazer login com seu e-mail e senha cadastrados. Caso tenha esquecido sua senha, clique em "Esqueci minha senha" e siga as instruções para redefini-la.',
      },
      {
        question: "Como faço para alterar meus dados cadastrais?",
        answer:
          'Para alterar seus dados cadastrais, basta acessar a plataforma Orion Sinais e clicar em "Configurações", no menu superior e faça as alterações necessárias. Não se esqueça de salvar as alterações antes de sair da página.',
      },
      {
        question: "Como faço para recuperar minha senha?",
        answer:
          'Caso tenha esquecido sua senha, basta acessar a plataforma Orion Sinais e clicar em "Esqueci minha senha". Você receberá um e-mail com as instruções para redefini-la. Se não encontrar o e-mail na caixa de entrada, verifique a pasta de spam ou lixo eletrônico.',
      },
    ],
  },
  {
    section: "general",
    questions: [
      {
        question: "Como posso comprar criptoativos?",
        answer:
          "Embora nossa plataforma forneça recomendações de compra e venda de ativos, não oferecemos serviços de negociação. As operações podem ser realizadas utilizando a plataforma de sua preferência.",
      },
      {
        question: "Adquirindo o serviço, o que preciso para começar a operar?",
        answer:
          "É aconselhável que você já possua USDT para operar. Caso contrário, será necessário converter seus ativos para essa moeda.",
      },
      {
        question:
          "Vocês ensinam a utilizar a Binance para registrar as operações?",
        answer:
          "Todas as recomendações são de sua responsabilidade, não nos responsabilizamos por perdas ou falhas na execução das operações.",
      },
      {
        question:
          "Como são selecionados os ativos para os quais são enviadas as recomendações de compra e venda?",
        answer:
          "Atualizamos diariamente a lista de ativos disponíveis na plataforma, com base na capitalização de mercado. Atualmente enviamos sinais para mais de 180 ativos, incluindo os mais populares e com maior liquidez.",
      },
      {
        question:
          "Preciso ter uma conta na Binance para operar? Ou posso operar em outras exchanges?",
        answer:
          "Embora recomendemos o uso da Binance devido à quantidade de operações e à liquidez, você pode operar em outras exchanges, seguindo as recomendações do par (por exemplo: BTC/USDT) desde que as condições de compra e venda sejam próximas às recomendadas.",
      },
      {
        question: 'Como vocês "preveem" o percentual de lucro dos ativos?',
        answer:
          'Não realizamos previsões nem estabelecemos metas para os ativos. Os sinais de venda são registrados quando ocorre a convergência das variáveis que os desencadeiam. Dessa forma, enquanto as condições para a geração do sinal de venda não forem atendidas, a operação permanece "em aberto", com status "parcial", permitindo que você avalie seu desempenho até o momento.',
      },
      {
        question: 'O que significa o "parcial"?',
        answer:
          '"Parcial" refere-se a operações de compra e venda que ainda estão em aberto. Ou seja, ocorreu um sinal de compra para um determinado ativo, e a operação aguarda o sinal de venda para encerrar o ciclo "compra-venda" do ativo. Além disso, a visualização "parcial" oferece a rentabilidade até o momento, caso deseje concluir a operação com base no sinal de compra inicial.',
      },
      {
        question:
          "Como faço para visualizar o histórico de sinais de compra e venda de um ativo específico?",
        answer:
          'Para visualizar o histórico de sinais de compra e venda de um ativo específico, basta acessar a plataforma e selecionar o ativo desejado na página "Dashboard". Em seguida, escolha o período desejado para consulta e visualize os sinais de compra e venda emitidos para aquele ativo.',
      },
      {
        question:
          "Como faço para avaliar a rentabilidade de um ativo em um período selecionado?",
        answer:
          "Para avaliar a rentabilidade de um ativo em um período selecionado, basta acessar a plataforma e utilizar o simulador de ganhos. Selecione o ativo desejado, o período de análise e a quantidade de USDT investida. O simulador calculará automaticamente a rentabilidade do ativo com base nas recomendações de compra e venda emitidas para aquele ativo.",
      },
      {
        question: "O que fazemos e o que NÃO fazemos?",
        answer:
          "Definimos uma estratégia de compra e venda utilizando metodologia de análise de mercado e entregamos através da plataforma o momento recomendado para a negociação. Entretanto não efetuamos para o cliente a execução da ordem, sendo assim sua responsabilidade a execução da ordem na corretora de sua preferência.",
      },
    ],
  },
  {
    section: "recommendations",
    questions: [
      {
        question:
          "Qual é a estratégia utilizada? E qual a frequência das recomendações?",
        answer:
          'Nossa estratégia consiste em operações de "swing trading", caracterizadas por sua duração, que pode variar de alguns dias a alguns meses.',
      },
      {
        question: "Eu recebo as recomendações em que formato?",
        answer:
          'As recomendações são fornecidas apenas no formato "par" USDT (dólar Tether), por exemplo: o ativo é BTC/USDT (Bitcoin / Dólar Tether).',
      },
      {
        question: "Quero aumentar meus Bitcoins, qual par é mais indicado?",
        answer:
          "Recomendamos que você converta seus ganhos de USDT para Bitcoin após o fechamento da recomendação de compra-venda realizada com outro ativo",
      },
      {
        question: "Qual a taxa de assertividade das recomendações enviadas?",
        answer:
          "Enviamos recomendações individualmente para cada ativo, portanto, a assertividade pode ser verificada através do histórico de cada ativo na plataforma. É importante ressaltar que a assertividade não é a única métrica considerada em nossas análises, pois nossa estratégia visa a lucratividade dos ativos a médio prazo, sem metas específicas.",
      },
      {
        question:
          "As recomendações são só em USDT? Tenho acesso a quantas recomendações?",
        answer:
          "Todas as recomendações são em pares USDT, com foco em aumentar sua quantidade de dólares. O número de recomendações varia conforme a disponibilidade de ativos na plataforma, que é atualizada diariamente. Você terá acesso a todas as recomendações, sem limites.",
      },
      {
        question: "Qual a periodicidade das recomendações de compra e venda?",
        answer:
          "A periodicidade varia conforme o ativo e a convergência de variáveis que sinalizam os momentos de entrada e saída (compra e venda). Enquanto não houver uma situação que justifique uma operação, a estratégia não emitirá nenhum sinal para aquele ativo.",
      },
    ],
  },
  {
    section: "payments",
    questions: [
      {
        question:
          "Qual é o período de vigência dos planos de assinatura disponíveis na Orion Sinais e como posso renovar ou atualizar minha assinatura?",
        answer:
          "Temos 3 tipos de planos atualmente: mensal, válido por 30 dias a partir da data de pagamento da assinatura. Trimestral, válido por 90 dias a partir da data de pagamento da assinatura e anual, válido por 365 dias a partir da data de pagamento da assinatura. Observação: a assinatura não é renovada automaticamente, mas enviamos um lembrete por e-mail quando estiver próximo do vencimento, para que você possa renovar ou atualizar sua assinatura!",
      },
      {
        question: "Como funciona o cancelamento e o reembolso?",
        answer:
          "Para solicitar o cancelamento da assinatura, basta enviar um e-mail para contato@orionsinais.com.br. Se solicitado nos primeiros 7 dias, você receberá um reembolso total da assinatura.",
      },
    ],
  },
];
