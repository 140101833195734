import { useState, useEffect } from "react";

import orionAPI from "../../../config/orionAPI";

const useCoinHistoryData = (pairSymbol) => {
  const [coinHistoryData, setCoinHistoryData] = useState([]);
  const [loadingCoinHistoryData, setLoadingCoinHistoryData] = useState(true);

  const fetchCoinHistoryData = async (pair) => {
    setLoadingCoinHistoryData(true);

    await orionAPI
      .get(`/api/dashboard/position_trade/${pair}/trades`)
      .then((response) => {
        setCoinHistoryData(response.data);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoadingCoinHistoryData(false);
      });
  };

  useEffect(() => {
    fetchCoinHistoryData(pairSymbol);
  }, [pairSymbol]);

  return { coinHistoryData, loadingCoinHistoryData };
};

export default useCoinHistoryData;
