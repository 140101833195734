import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import logo from "../../assets/logo-light.png";
import userIcon from "../../assets/default_icon.svg";
import "./navbar.css";

import { useLocation } from "react-router-dom";

import {
  WalletIcon,
  Cog6ToothIcon,
  Bars3Icon,
  XMarkIcon,
  ArrowLeftEndOnRectangleIcon,
} from "@heroicons/react/24/outline";

import useAuth from "../../hooks/useAuth";
import useUser from "../../hooks/useUser";

function Navbar() {
  const [stickyNavbar, setStickyNavbar] = useState(false);
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const navigate = useNavigate();

  const { user, loadingUser } = useUser();
  const { logout, isAuthenticated } = useAuth();

  let location = useLocation();

  const handleLogout = () => {
    logout();
    if (!isAuthenticated()) navigate("/login");
  };

  const changeNavbarToSticky = () => {
    if (window.scrollY > 20) {
      setStickyNavbar(true);
    } else {
      setStickyNavbar(false);
    }
  };

  window.addEventListener("scroll", changeNavbarToSticky);

  return (
    <div className={stickyNavbar ? "orion__navbar sticky" : "orion__navbar"}>
      <div className="orion__navbar-links">
        <div
          className="orion__navbar-links_logo"
          onClick={() => navigate("/dashboard")}
        >
          <img src={logo} alt="logo" className="orion__nav-logo" />
        </div>
      </div>
      {location.pathname === "/assinatura" ||
      location.pathname === "/assinatura-terminada" ? (
        <></>
      ) : (
        <ul className="orion__navbar-menu">
          <li
            className={`orion__menu-item ${
              location.pathname === "/dashboard" ||
              location.pathname === "/sinais-abertos"
                ? "active"
                : ""
            }`}
            onClick={() => navigate("/dashboard")}
          >
            <WalletIcon className="orion__menu-icon" />
            <p>Dashboard</p>
          </li>
          <li
            className={`orion__menu-item ${
              location.pathname === "/configuracoes" ? "active" : ""
            }`}
            onClick={() => navigate("/configuracoes")}
          >
            <Cog6ToothIcon className="orion__menu-icon" />
            <p>Configurações</p>
          </li>
        </ul>
      )}
      <li className="orion__menu-toggle">
        <Bars3Icon
          className="orion__menu-icon-mobile"
          onClick={() => setShowMobileMenu(true)}
        />
      </li>
      {!showMobileMenu && (
        <div className="orion__userInfo">
          <img className="orion__userInfo-img" src={userIcon} />
          <div className="orion__userInfo-text">
            <div className="orion__userInfo-username">
              {loadingUser ? "" : user.first_name + " " + user.last_name}
            </div>
            <a onClick={handleLogout}>Sair</a>
          </div>
        </div>
      )}

      {showMobileMenu && (
        <div className="orion__menu-sidebar">
          <ul>
            <li>
              <XMarkIcon
                className="orion__menu-icon-mobile"
                onClick={() => setShowMobileMenu(false)}
              />
            </li>
            <li
              className={`orion__menu-item ${
                location.pathname === "/dashboard" ||
                location.pathname === "/sinais-abertos"
                  ? "active"
                  : ""
              }`}
              onClick={() => navigate("/dashboard")}
            >
              <WalletIcon className="orion__menu-icon" />
              <p>Dashboard</p>
            </li>
            <li
              className={`orion__menu-item ${
                location.pathname === "/configuracoes" ? "active" : ""
              }`}
              onClick={() => navigate("/configuracoes")}
            >
              <Cog6ToothIcon className="orion__menu-icon" />
              <p>Configurações</p>
            </li>
            <li>
              <div className="orion__user-info-mobile">
                <img className="orion__userInfo-img" src={userIcon} />
                <div className="orion__userInfo-text">
                  <div className="orion__userInfo-username">
                    {loadingUser ? "" : user.first_name + " " + user.last_name}
                  </div>
                </div>
                <a className="orion__logout" onClick={handleLogout}>
                  <ArrowLeftEndOnRectangleIcon className="orion__menu-icon-mobile" />
                </a>
              </div>
            </li>
          </ul>
        </div>
      )}
    </div>
  );
}

export default Navbar;
