import React, { useRef } from "react";
import "./subscriptionFirstSection.css";
import ClipLoader from "react-spinners/ClipLoader";
import orionAPI from "../../../config/orionAPI";
import { loadStripe } from "@stripe/stripe-js";
import { Button } from "../../../components";

function SubscriptionFirstSection({
  selectedPlan,
  subscriptionPlans,
  setSelectedPlan,
  setShowPlanNotSelectedWarning,
  setPage,
  page,
  loading,
}) {
  const ref = useRef([]);

  const handleSelect = (e) => {
    let selected;

    for (let i = 0; i < ref.current.length; i++) {
      if (ref.current[i].name === e.target.name) {
        ref.current[i].checked = true;
        selected = ref.current[i];
      } else {
        ref.current[i].checked = false;
      }
    }

    let selectedPlanData = subscriptionPlans.find(
      (plan) => plan.name === selected.name
    );

    setSelectedPlan({
      ...selectedPlanData,
    });
  };

  const handlePixSubmit = () => {
    if (selectedPlan.id) {
      setShowPlanNotSelectedWarning(false);
      setPage(page + 1);
    } else {
      setShowPlanNotSelectedWarning(true);
    }
  };

  const handleStripeSubmit = async (planId) => {
    const stripe = await loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

    if (!stripe) return;

    const res = await orionAPI.post("api/users/me/subscribe/checkout", {
      subscription_plan_id: planId,
    });

    console.log(res.data);

    stripe.redirectToCheckout({
      sessionId: res.data.data.checkout_id,
    });
  };

  return (
    <>
      <div className="orion__subscription-wrapper">
        {loading ? (
          <ClipLoader
            color="#D42A5B"
            size={50}
            aria-label="Loading Spinner"
            data-testid="loader"
            className="orion__loading"
          />
        ) : (
          subscriptionPlans.map((plan, i) => {
            return (
              <div className="orion__price-button" key={plan.id}>
                <input
                  ref={(element) => {
                    ref.current[i] = element;
                  }}
                  type="radio"
                  onChange={handleSelect}
                  name={plan.name}
                />
                <h4 className="orion__price">
                  {plan.price + " " + plan.currency}
                </h4>
                <small className="orion__duration">
                  PLANO DE {plan.duration_in_days} DIAS
                </small>
              </div>
            );
          })
        )}
        <Button
          styleType="filled"
          onClick={() =>
            selectedPlan.id
              ? selectedPlan.currency === "BRL"
                ? handlePixSubmit()
                : handleStripeSubmit(selectedPlan.id)
              : handlePixSubmit
          }
          text="Continuar"
        />
      </div>
    </>
  );
}

export default SubscriptionFirstSection;
